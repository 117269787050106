import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { environment } from '../../environments/environment';
import { ImageHelpers } from '../infrastructure/helpers/image.helpers';

@Component({
    selector: 'fe-lazy-img-or-logo',
    template: ` <img [lazyLoad]="getImage()" [defaultImage]="defaultImage" />`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            :host {
                ::ng-deep img {
                    display: block;
                    width: 300px;
                    height: 300px;
                }

                &.small {
                    ::ng-deep img {
                        width: 66px;
                        height: 24px;
                    }
                }
            }
        `,
    ],
})
export class LazyImageComponent {
    private theme: string = environment.theme.marketplaceType;

    @Input()
    imageUrl: string | undefined | null;

    @HostBinding('class.small')
    @Input()
    small = false;

    getImage() {
        return this.imageUrl ? this.imageUrl : `../../assets/img/${this.theme}/logo.svg`;
    }

    get defaultImage() {
        return ImageHelpers.getDefaultImage();
    }
}
