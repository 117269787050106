import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'fe-multiselect-input',
    templateUrl: 'multiselect-input.component.html',
    styles: [
        `
            .chip-list:not(.dense) {
                width: 100%;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AttributesInputComponent),
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributesInputComponent implements ControlValueAccessor, OnInit {
    separatorKeysCodes: number[] = [ENTER, COMMA];
    metadataCtrl = new FormControl();
    filteredMetadataKeys: Observable<string[]>;
    items: string[] = [];

    _suggestions: string[] = [];
    @Input() set suggestions(value: string[]) {
        this._suggestions = value;
        this.filteredMetadataKeys = this.metadataCtrl.valueChanges.pipe(
            startWith(null),
            map((fruit: string | null) => (fruit ? this._filter(fruit) : this._suggestions.slice())),
        );
    }

    @Input()
    label: string;

    @Input()
    dense: boolean = false;

    @Input() formControlName: string;

    @Input()
    inputValidatorFn?: (value: string) => boolean;

    @Input()
    suggestionExtractorFn?: (value: any) => string;

    _isFormSubmitted = false;
    @Input() set isFormSubmitted(value: boolean) {
        this._isFormSubmitted = value;
        this.setChipListValidity();
    }

    onChange = (_: any) => {};
    onTouched = () => {};

    @ViewChild('chipList')
    chipList: MatChipList;

    @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;

    private parentCtrl: AbstractControl;
    private newValuePrefix = '<new>';

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
    ) {}

    ngOnInit() {
        this.parentCtrl = this.controlContainer.control!.get(this.formControlName)!;
        const validators = this.parentCtrl.validator;
        this.metadataCtrl.setValidators(validators ? validators : null);
        this.metadataCtrl.updateValueAndValidity();
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    writeValue(items: any) {
        if (!Array.isArray(items)) {
            this.items = [];
            return;
        }

        this.items = items || [];
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    add(event: MatChipInputEvent): void {
        let value = (event.value || '').trim();

        if (this.inputValidatorFn && !this.inputValidatorFn(value)) {
            return;
        }

        value = this.setNewMarkOnValue(value);

        this.items.push(value);
        event.chipInput!.clear();

        this.metadataCtrl.setValue(null);
        this.onChange(this.items);
        this.setChipListValidity();
    }

    private setNewMarkOnValue(value: string) {
        const possibleSuggestion = this.suggestionExtractorFn ? this.suggestionExtractorFn!(value) : value;
        const existingSuggestion = this._suggestions.find((s) => s === possibleSuggestion);
        value += existingSuggestion ? '' : this.newValuePrefix;

        return value;
    }

    remove(index: number): void {
        this.items.splice(index, 1);
        this.onChange(this.items);
        this.setChipListValidity();
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.fruitInput.nativeElement.value = event.option.viewValue;
        this.metadataCtrl.setValue(event.option.viewValue);
    }

    trackByFn(_: number, item: string) {
        return item;
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this._suggestions.filter((item) => item.toLowerCase().includes(filterValue));
    }

    private hasRequiredValidator() {
        return this.metadataCtrl?.errors && (this.metadataCtrl?.errors)!['required'];
    }

    private setChipListValidity() {
        if (!this.chipList) {
            return;
        }

        const isInvalid =
            (this._isFormSubmitted || this.metadataCtrl?.touched) &&
            this.hasRequiredValidator() &&
            this.items.length === 0;

        if (isInvalid) {
            this.metadataCtrl.markAsTouched();
        } else {
            this.metadataCtrl.markAsUntouched();
        }

        this.chipList.errorState = isInvalid;
    }
}
