import { Pipe, PipeTransform } from '@angular/core';
import { CartItem } from '../../cart/models/cart-item';
import { TokenResponseModel } from '../../infrastructure/web-api/api-contract';

@Pipe({
    name: 'toTokenName',
})
export class ToTokenNamePipe implements PipeTransform {
    transform(token: TokenResponseModel | CartItem): string {
        return `${token && token.name ? token.name : ''} #${token.NFTTokenId}`;
    }
}
