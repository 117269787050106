import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BaseDialogComponent } from './dialog.component';
import { FileSelectComponent } from './file-select/file-select.component';
import { LazyImageComponent } from './lazy-image.component';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { RadioComponent } from './radio/radio.component';
import { SellItemComponent } from './sell-item/sell-item.component';
import { SpinningIconComponent } from './spinning-icon/spinning-icon.component';
import { ButtonComponent } from './button/button.component';
import { IconRegistryService } from './services/icon-registry-service';
import { InputComponent } from './input/input.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { DropdownComponent } from './dropdown/dropdown.component';
import { PanelComponent } from './panel/panel.component';
import { ObserversModule } from '@angular/cdk/observers';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CartSummaryComponent } from './cart-summary/cart-summary.component';
import { FePaginator } from './paginator/paginator.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_PAGINATOR_INTL_PROVIDER } from '@angular/material/paginator';
import { CopyClipboardDirective } from './directives/copy-to-clipboard.directive';

const declaration = [
    SellItemComponent,
    LazyImageComponent,
    FileSelectComponent,
    BaseDialogComponent,
    ButtonComponent,
    SpinningIconComponent,
    RadioComponent,
    InputComponent,
    SearchBoxComponent,
    DropdownComponent,
    ClickStopPropagationDirective,
    CopyClipboardDirective,
    PanelComponent,
    RangeSliderComponent,
    CartSummaryComponent,
    FePaginator,
];

@NgModule({
    imports: [
        CommonModule,
        MatTooltipModule,
        LazyLoadImageModule,
        SharedPipesModule,
        MatIconModule,
        ObserversModule,
        NgxSliderModule,
    ],
    declarations: declaration,
    exports: declaration,
    providers: [IconRegistryService, MAT_PAGINATOR_INTL_PROVIDER],
    entryComponents: [],
})
export class SharedModule {}
