import { Pipe, PipeTransform } from '@angular/core';
import { PriceHelpers } from '../../infrastructure/helpers/price.helpers';

@Pipe({
    name: 'priceDiscount',
})
export class PriceWithDiscountPrice implements PipeTransform {
    transform(price: number, discountInPercent: number): string {
        return PriceHelpers.getPriceWithDiscount(price, discountInPercent).toFixed(2);
    }
}
