import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { IconMap } from "../icons/icon-map.type";
import { sharedIcons } from "../icons/shared-icons";

@Injectable({ providedIn: 'root' })
export class IconRegistryService {
    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) { }

    initialize(icons: IconMap): void {
        this.registerSharedIcons();
        this.registerIcons(icons);
    }

    private registerSharedIcons():void {
        this.registerIcons(sharedIcons);
    }

    private registerIcons(iconMap: IconMap):void {
        for (const key in iconMap) {
            this.registerIcon(key, iconMap[key]);
        }
    }

    private registerIcon(iconName: string, iconPath: string):void {
        this.matIconRegistry.addSvgIcon(iconName, this.domSanitizer.bypassSecurityTrustResourceUrl(iconPath));
    }
}