import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, pipe, throwError } from 'rxjs';
import { LocalOrder } from '../ertha/models/order';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { GasPriceService } from '../gas-price/gas-price.service';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { usdtContractAbi } from './contracts/usdt-contract-abi';
import { PaymentMethodEnum } from './payment-method-enum';
import { EthereumParams } from './ethereum-params';
import { EthereumStatus } from './ethereum-status';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class EthereumApi {
    ethereum = (window as any)['ethereum'];
    status$: Observable<EthereumStatus>;

    private statusSubject = new BehaviorSubject<EthereumStatus>({
        accountId: null,
        error: null,
        loading: false,
        notAvailable: !this.ethereum,
    });

    constructor(private gasPriceService: GasPriceService) {
        this.status$ = this.statusSubject.asObservable();
        if (!this.ethereum) {
            return;
        }

        setTimeout(() => {
            if (this.ethereum.selectedAddress) {
                this.setAccount(this.ethereum.selectedAddress);
            }
        }, 500);

        this.ethereum.on('accountsChanged', (accounts: string[]) => {
            if (accounts.length > 0) {
                this.statusSubject.next({
                    loading: false,
                    accountId: accounts[0],
                    error: null,
                });
            } else {
                this.statusSubject.next({
                    loading: false,
                    accountId: null,
                    error: null,
                });
            }
        });
    }

    getAccount(): Observable<string | null> {
        return this.status$.pipe(
            take(1),
            switchMap((status) => {
                if (!this.ethereum) {
                    this.statusSubject.next({
                        accountId: null,
                        error: { message: 'Ethereum is not supported on this browser...' },
                        loading: false,
                        notAvailable: true,
                    });
                }
                if (status.accountId) {
                    return of(status.accountId);
                }
                const promise = this.ethereum.request({ method: 'eth_requestAccounts' }) as Promise<string[]>;
                this.setLoading();
                return from(promise).pipe(
                    map((accounts) => {
                        return accounts[0];
                    }),
                    catchError((err) => {
                        this.handleError(err);
                        return of(null);
                    }),
                );
            }),
        );
    }

    setLoading(): void {
        this.statusSubject.next({
            loading: true,
            accountId: null,
            error: null,
        });
    }

    login(): void {
        this.getAccount()
            .pipe(
                catchError((err) => {
                    console.log(err);
                    return throwError(() => err);
                }),
            )
            .subscribe((accountId) => {
                console.log(accountId);
                this.setAccount(accountId);
            });
    }

    setAccount(accountId: string | null): void {
        this.statusSubject.next({
            loading: false,
            error: null,
            accountId: accountId,
        });
    }

    handleError(err: any): void {
        this.statusSubject.next({ accountId: null, loading: false, error: err });
    }

    createTransaction(order: LocalOrder, type: PaymentMethodEnum): Observable<any> {
        const account$ = this.getAccount();
        const switchChain$ = this.createSwitchChainStream();

        return account$.pipe(
            switchMap((accountId) => {
                if (!accountId) {
                    throw new Error('MetaMask account not available');
                }
                return switchChain$.pipe(
                    switchMap(() => this.createParams(order, accountId, type)),
                    switchMap((params) => this.sendTransaction(params)),
                );
            }),
        );
    }

    private sendTransaction(params: EthereumParams): Observable<any> {
        return of(null).pipe(
            switchMap(() => {
                return from(this.ethereum.request({ method: 'eth_sendTransaction', params: [params] }));
            }),
        );
        // const account$ = this.status$.pipe(take(1), map((status) => status?.accountId));
        // const gasPrice$ = this.gasPriceService.gasPrice$;
    }

    private createParams(order: LocalOrder, accountId: string, type: PaymentMethodEnum): Observable<EthereumParams> {
        return this.gasPriceService.gasPrice$.pipe(
            map((gasPrice) => {
                if (type === PaymentMethodEnum.BNB) {
                    const paymentAmount = new BigNumber(parseInt(order.paymentAmountBNB));
                    return {
                        from: accountId,
                        value: `0x${paymentAmount.toString(16)}`,
                        gasPriceWeiHex: gasPrice.priceWeiHex,
                        gas: '0x5208',
                        to: order.paymentAddress,
                    };
                } else if (type === PaymentMethodEnum.USDT) {
                    const int = new ethers.utils.Interface(usdtContractAbi);
                    const data = int.encodeFunctionData('transfer', [order.paymentAddress, order.paymentAmountUSDT]);
                    return {
                        from: accountId,
                        value: '0x0',
                        gasPriceWeiHex: gasPrice.priceWeiHex,
                        gas: '0x249F0', // 150000
                        to: environment.usdtContractAddress, // contract address
                        data: data,
                    };
                } else if (type === PaymentMethodEnum.USDC) {
                    const int = new ethers.utils.Interface(usdtContractAbi);
                    const data = int.encodeFunctionData('transfer', [order.paymentAddress, order.paymentAmountUSDC]);
                    return {
                        from: accountId,
                        value: '0x0',
                        gasPriceWeiHex: gasPrice.priceWeiHex,
                        gas: '0x249F0', // 150000
                        to: environment.usdcContractAddress, // contract address
                        data: data,
                    };
                } else if (type === PaymentMethodEnum.ERTHA) {
                    const int = new ethers.utils.Interface(usdtContractAbi);
                    const data = int.encodeFunctionData('transfer', [order.paymentAddress, order.paymentAmountERTHA]);
                    return {
                        from: accountId,
                        value: `0x0`,
                        gasPriceWeiHex: gasPrice.priceWeiHex,
                        gas: '0x249F0', // 150000
                        to: environment.erthaContractAddress, // contract address
                        data: data,
                    };
                }
                throw new Error(`Unsupported payment type "${type}"`);
            }),
        );
    }

    private createSwitchChainStream(): Observable<any> {
        return from(
            this.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: environment.chainId }], // chainId must be in hexadecimal numbers
            }),
        ).pipe(
            catchError((error) => {
                if (error.code === 4902) {
                    return from(
                        this.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: environment.chainId,
                                    chainName: environment.chainName,
                                    rpcUrls: environment.rpcUrls,
                                    nativeCurrency: {
                                        symbol: environment.chainSymbol,
                                        decimals: 18,
                                    },
                                    blockExplorerUrls: environment.blockExplorerUrls,
                                },
                            ],
                        }),
                    );
                } else {
                    throw new Error(error);
                }
            }),
            catchError((error) => {
                console.log(error);
                throw error;
            }),
        );
    }
}
