import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DeviceDetectionService {
    readonly isMobile$: Observable<boolean>;
    readonly isMobilePure$: Observable<boolean>;

    private skipDetectionSubject = new BehaviorSubject(localStorage.getItem('skip-dd') === 'true');

    constructor() {
        this.isMobilePure$ = fromEvent(window, 'resize').pipe(
            map(() => window.innerWidth),
            startWith(window.innerWidth),
            map((width) => width <= 760),
            distinctUntilChanged(),
        );
        this.isMobile$ = combineLatest([this.isMobilePure$, this.skipDetectionSubject]).pipe(
            map(([isMobile, skipDetection]) => isMobile && !skipDetection),
        );
        // this.isMobile$ = of(false);
        // this.isMobile$ = of(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }

    setSkipDetection(skipDetection: boolean): void {
        this.skipDetectionSubject.next(skipDetection);
        localStorage.setItem('skip-dd', `${skipDetection}`);
    }
}
