import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ng-state/store';
import { NotifierService } from 'angular-notifier';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RootState, MarketplaceState } from '../../state';
import { AuthService } from '../services/auth.service';
import { ComponentService } from '../services/component.service';
import { InterceptorHttpParams } from './interceptor-http-params';
import { WebApiDataService } from './web-api-data.service';

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private componentService: ComponentService,
        private webApiData: WebApiDataService,
        private authService: AuthService,
        private store: Store<MarketplaceState>,
        private notifier: NotifierService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let showSpinner = true;
        let spinnerMessage = '';

        if (this.isApiCall(req)) {
            req = this.addHeaders(req);
        }

        if (req.params instanceof InterceptorHttpParams) {
            showSpinner = req.params.showSpinner;
            spinnerMessage = req.params.spinnerMessage;
        }

        if (showSpinner) {
            this.webApiData.increaseRequestsCount();
            this.componentService.spinner.show(spinnerMessage);
        }

        return next.handle(req).pipe(
            catchError((err: any) => this.onError(err, req, next)),
            finalize(() => {
                this.onHttpCallEnd(showSpinner, spinnerMessage);
            }),
        );
    }
    private isApiCall(req: HttpRequest<any>) {
        return req.url.indexOf(environment.apiUrl) !== -1;
    }

    private onHttpCallEnd(showSpinner: boolean, spinnerMessage?: string) {
        if (showSpinner) {
            this.webApiData.decreaseRequestsCount();
        }

        if (this.webApiData.pendingRequestsCount === 0) {
            this.componentService.spinner.hide(spinnerMessage);
        }
    }

    private onError(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (
            error instanceof HttpErrorResponse &&
            !req.url.includes(this.authService.refreshTokenUrl) &&
            error.status === 401
        ) {
            return this.handle401Error(req, next);
        }

        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
            console.log('this is client side error');
            errorMsg = `Error: ${error.error.message}`;
        } else {
            console.log('this is server side error');
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            this.notifier.notify('error', error.error.message ? error.error.message : 'There was some problems');
        }

        // TODO: error reporting to Sentry

        console.log(errorMsg);
        return throwError(() => errorMsg);
    }

    private addHeaders(req: HttpRequest<any>): HttpRequest<any> {
        let marketplaceType = '';
        let token = '';
        this.store
            .select<RootState>(['root'])
            .pipe(take(1))
            .subscribe((s) => {
                token = s.user?.token ?? '';
                marketplaceType = s.marketplaceType;
            });

        if (marketplaceType) {
            req = req.clone({
                headers: req.headers.set('marketplaceType', marketplaceType),
            });
        }

        if (token) {
            req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`),
            });
        }

        return req;
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((_: any) => {
                    this.isRefreshing = false;

                    this.refreshTokenSubject.next('');
                    request = this.addHeaders(request);
                    return next.handle(this.addHeaders(request));
                }),
                catchError((err) => {
                    this.isRefreshing = false;

                    this.authService.signOut();
                    this.notifier.notify('error', 'You are not authorized');
                    return throwError(() => err);
                }),
            );
        }

        return this.refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap(() => next.handle(this.addHeaders(request))),
        );
    }
}
