<div class="sell-item-container" (click)="onItemSelected()">
    <div class="image-container" [ngClass]="isSmall ? 'small' : ''">
        <div class="image-mask" [lazyLoad]="backgroundImage" [defaultImage]="defaultImage">
        </div>
    </div>
    <div class="item-info-container">
        <div class="item-info">
            <div class="item-label">{{ item.marketplaceType }}</div>
            <div class="item-label">Price</div>
        </div>
        <div class="item-info">
            <div class="item-value">{{ item.name }}</div>
            <div class="item-value" *ngIf="!discountInPercent || discountInPercent.toString() === '0'; else priceWithDiscount">
                {{ price | currency: 'USD' }}
            </div>
        </div>
        <div class="price-history">
            <div class="item-label">Last</div>
            <div class="last-price" *ngIf="!discountInPercent || discountInPercent.toString() === '0'; else priceWithDiscount">
                {{ price | currency: 'USD' }}
            </div>
        </div>
        <div class="item-info-separator"></div>
        <div class="button-container">
                <div *ngIf="item.isSold || !item.isForSale" class="reserved">Sold</div>
                <button fe-button 
                    click-stop-propagation 
                    class="btn-quaternary" 
                    *ngIf="!item.isReserved &&
                           !(isTokenInCart(item._id) | async) &&
                           item.price > 0 &&
                           !item.isSold &&
                           item.isForSale"
                    (click)="addToCart(item)">
                    buy now
                </button>
                <button fe-button 
                    click-stop-propagation 
                    class="btn-quaternary" 
                    *ngIf="!!(isTokenInCart(item._id) | async)" 
                    (click)="removeFromCart(item._id)">
                    remove from cart
            </button>
                <div *ngIf="item.isReserved" class="reserved">Reserved</div>
                <div class="favorite-button-container">
                    <!-- <mat-icon class="favorite-button icon-color-primary" svgIcon="heart"></mat-icon>
                    <span class="favorite-counter">19</span> -->
                </div>
            <!-- <div otherButtons>
                <a class="primary-button" [routerLink]="['/globe']" fragment="{{ item.NFTTokenId }}">
                    <mat-icon>public</mat-icon>
                </a>
                <mat-icon
                    class="warning-button"
                    *ngIf="authService.isLoggedIn(['admin']) | async"
                    (click)="onTokenEditClick(item.NFTTokenId)"
                    >edit</mat-icon
                >
            </div> -->
        </div>
    </div>
</div>
<ng-template #priceWithDiscount>
    <div>
        <span class="old-price">{{ price | currency: 'USD' }}</span
        ><span class="new-price">{{ price | priceDiscount: discountInPercent | currency: 'USD' }}</span>
    </div>
</ng-template>
