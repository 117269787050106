import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { FileUploadUrlData } from '../models/file-upload-url-data';
import { WebApiService } from '../web-api/web-api.service';

@Injectable({ providedIn: 'root' })
export class FileService {
    constructor(private webApiService: WebApiService) {}

    uploadImages(space: string, files: File[]): Observable<any[]> {
        return this.webApiService
            .post('files/get-file-upload-urls', {
                space,
                files: files.map((f) => f.name),
            })
            .pipe(
                mergeMap((uploadUrls: FileUploadUrlData[]) => {
                    if (!uploadUrls || uploadUrls.length === 0) {
                        // TODO: remove
                        return of(files.map((f) => f.name));
                    }

                    const retVal = <Observable<Object>[]>[];
                    for (let index = 0; index < uploadUrls.length; index++) {
                        const fileUploader = this.webApiService.put({
                            url: uploadUrls[index].uploadUrl,
                            requestData: files[index],
                            returnRawResponse: true,
                            isApiCall: false,
                        });
                        retVal.push(fileUploader);
                    }

                    return combineLatest(retVal).pipe(map(() => uploadUrls.map((t) => t.filePath)));
                }),
            );
    }
}
