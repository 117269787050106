import { Injectable } from '@angular/core';
import { Store } from '@ng-state/store';
import { Observable, Subject } from 'rxjs';
import { Classifier } from './infrastructure/web-api/api-contract';
import { RootState } from './state';

@Injectable({ providedIn: 'root' })
export class AppService {
    refreshClassifiers = new Subject<any>();
    onTokenEdit = new Subject<number>();

    constructor(private store$: Store<RootState>) {}

    getClassifierByType(type: string, childOfType?: string): Observable<Classifier[]> {
        return this.store$
            .select(['root', 'classifiers'])
            .map((classifiers) =>
                classifiers.filter(
                    (classifier: Classifier) =>
                        classifier.type === type && (childOfType ? classifier.isChildOfType === childOfType : true),
                ),
            );
    }
}
