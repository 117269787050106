import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'input[feInput]',
    styleUrls: ['./input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush, //not sure about this
    template: `<ng-content></ng-content>`,
})
export class InputComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
