import 'zone.js/plugins/zone-error';

const apiUrl = 'https://api.test.outfission.com';
const earthCdnUrl = 'https://ertha.fra1.digitaloceanspaces.com/00_Unity_Builds/mainnet/earth';

export const environment = {
    production: true,
    apiUrl: apiUrl,
    loaderUrl: `${earthCdnUrl}/WebTool.loader.js`,
    dataUrl: `${earthCdnUrl}/WebTool.data.br`,
    frameworkUrl: `${earthCdnUrl}/WebTool.framework.js.br`,
    codeUrl: `${earthCdnUrl}/WebTool.wasm.br`,
    streamingAssetsUrl: `${earthCdnUrl}/StreamingAssets`,
    usdtContractAddress: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
    usdcContractAddress: '0x64544969ed7EBf5f083679233325356EbE738930',
    erthaContractAddress: '0xE4a1b6BB50B3D34F5A2c1c87900F615A9E043bBc',
    attributesUrl: `${apiUrl}/attributes.bin`,
    statusUrl: `${apiUrl}/status.bin`,
    statusWSUrl: 'wss://' + window.location.origin.replace(/https?:\/\//, ''),
    chainId: '0x61',
    chainName: 'Binance Smart Chain Testnet',
    chainSymbol: 'tBNB',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    blockExplorerUrls: ['https://bscscan.com'],
    captchaToken: '6LcbzyMeAAAAADBukYWNEYYjnaUERdz1dMWhTP_G',
    googleTagManagerToken: 'GTM-T49BGDW',
    firebaseConfig: {
        apiKey: 'AIzaSyB5REL6xluaJghvpSgg5DFOo6HUxLhLhCg',
        authDomain: 'ertha-1d89e.firebaseapp.com',
        projectId: 'ertha-1d89e',
        storageBucket: 'ertha-1d89e.appspot.com',
        messagingSenderId: '947527800766',
        appId: '1:947527800766:web:36b56823161c312d390bef',
    },
};
