import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

injectCaptchaScript();
injectGoogleTagScript();

function injectCaptchaScript() {
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('defer', '');
    script.id = 'recaptchaScript';
    script.src = `https://www.google.com/recaptcha/api.js?render=${environment.theme.captchaToken}`;
    document.getElementsByTagName('head')[0].appendChild(script);
}

function injectGoogleTagScript() {
    const headScript = document.createElement('script');
    headScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${environment.theme.googleTagManagerToken}');`;
    document.getElementsByTagName('head')[0].appendChild(headScript);
}