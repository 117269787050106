<div class="footer-container">
    <div class="section">
        <mat-icon class="footer-logo icon-color-secondary" svgIcon="logo"></mat-icon>
        <div class="marketplace-info">
            <a href="#"><p>land@moon.ws</p></a> 
            <p>Moon is part of <a class="underline" target="_blank" href="#">Solar Metaverse</a></p>
            <p><a class="warning" href="#">Back to top</a></p>
        </div>
    </div>
    <div class="section game-info">
        <p>Moon game is created by Three Towers game studio</p>
    </div>
    <div class="section">
        <div class="sub-section">
            <div class="links">
                <a target="_blank" href="#">White paper</a>
                <a target="_blank" href="#">Roadmap & tokenomics</a>
                <a target="_blank" href="#">Moon landowners benefits</a>
                <a target="_blank" href="#">Moon gameplay</a>
            </div>
            <div class="links">
                <a target="_blank" href="#">Medium</a>
                <a target="_blank" href="#">Twitter</a>
                <a target="_blank" href="#">Discord</a>
                <a target="_blank" href="#">Telegram</a>
            </div>
        </div>
        <div class="sub-section">
            <p>Privacy Policy</p>
            <p>All rights reserved © MOON 2021</p>
        </div>
    </div>
</div>