import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EthereumStatusComponent } from './ethereum-status.component';
import { CentralMessageComponent } from './central-message/central-message.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SharedModule } from '../shared/shared.module';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [EthereumStatusComponent, CentralMessageComponent],
    exports: [EthereumStatusComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatButtonModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        MatInputModule,
    ],
})
export class EthereumStatusModule {}
