import { MarketplaceType } from '../../infrastructure/enums/marketplace-type';
import { ErthaLandplotPriceCalculationStrategy } from './strategies/ertha-landplot-price-calculation.strategy';

export const PriceCalculationStrategyKey = (marketplaceType: keyof typeof MarketplaceType, tokenType: string[]) =>
    `${marketplaceType.toString()}::${tokenType.join('-')}`;

export const PriceCalculationStrategyMap = new Map<string, any>([
    [PriceCalculationStrategyKey(MarketplaceType.ertha, ['landplot']), ErthaLandplotPriceCalculationStrategy],
    [PriceCalculationStrategyKey(MarketplaceType.moon, ['landplot']), ErthaLandplotPriceCalculationStrategy],
    [PriceCalculationStrategyKey(MarketplaceType.spellfire, ['landplot']), ErthaLandplotPriceCalculationStrategy],
]);
