import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, share, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ComponentHelper } from '../helpers/component.helpers';
import { BaseService } from '../services/base.service';
import { InterceptorHttpParams } from './interceptor-http-params';
import { WebApiDataService } from './web-api-data.service';

@Injectable({ providedIn: 'root' })
export class WebApiService extends BaseService {
    private cancelPendingRequests = new Subject();

    constructor(
        private http: HttpClient,
        private injector: Injector,
        private webApiData: WebApiDataService,
        private notifier: NotifierService,
    ) {
        super();
    }

    init() {
        const router = this.injector.get(Router);
        return ComponentHelper.navigationStartFilter(router.events)
            .pipe(takeUntil(this.isDestroyed))
            .subscribe(() => {
                this.webApiData.clear();
                this.cancelPendingRequests.next(true);
            });
    }

    get windowLocation() {
        if (!window['location']) {
            return '';
        }

        return window['location'].href.split('/').slice(0, 3).join('/');
    }

    get({
        url,
        requestData = null,
        isApiCall = true,
        options = {},
        returnRawResponse = false,
    }: {
        url: string;
        requestData?: any;
        isApiCall?: boolean;
        options?: any;
        returnRawResponse?: boolean;
    }): Observable<any> {
        url = this.getUrl(url, isApiCall);
        url = this.getUrlWithParams(url, requestData);
        const request = this.http.get(url, { ...options, observe: 'response' });
        return this.baseRequest(request, isApiCall, returnRawResponse);
    }

    getBuffer({
        url,
        requestData = null,
        isApiCall = true,
        options = {},
        returnRawResponse = false,
    }: {
        url: string;
        requestData?: any;
        isApiCall?: boolean;
        options?: any;
        returnRawResponse?: boolean;
    }): Observable<any> {
        url = this.getUrl(url, isApiCall);
        url = this.getUrlWithParams(url, requestData);
        const request = this.http.get(url, { ...options, responseType: 'arraybuffer' });
        return this.baseRequest(request, isApiCall, returnRawResponse);
    }

    post(
        url: string,
        body: any,
        {
            isApiCall = true,
            showSpinner = true,
            spinnerMessage = '',
            options = {},
        }: {
            isApiCall?: boolean;
            showSpinner?: boolean;
            spinnerMessage?: string;
            options?: any;
        } = {},
    ): Observable<any> {
        const params = new InterceptorHttpParams(showSpinner, spinnerMessage);
        url = this.getUrl(url, isApiCall);
        const request = this.http.post(url, body, { ...options, observe: 'response', params });
        console.log(url);
        return this.baseRequest(request, isApiCall);
    }

    put({
        url,
        requestData = {},
        isApiCall = true,
        returnRawResponse = false,
        spinnerMessage = '',
        showSpinner = true,
    }: {
        url: string;
        requestData?: any;
        isApiCall?: boolean;
        returnRawResponse?: boolean;
        spinnerMessage?: string;
        showSpinner?: boolean;
    }): Observable<any> {
        const params = new InterceptorHttpParams(showSpinner, spinnerMessage);
        url = this.getUrl(url, isApiCall);
        const request = this.http.put(url, requestData, { observe: 'response', params });
        return this.baseRequest(request, isApiCall, returnRawResponse);
    }

    delete(url: string, options: any = [], isApiCall = true): Observable<any> {
        url = this.getUrl(url, isApiCall);
        const request = this.http.delete(url, { ...options, observe: 'response' });
        return this.baseRequest(request, isApiCall);
    }

    getUrl(url: string, isApiCall: boolean = true) {
        return isApiCall ? `${environment.apiUrl}/${url}` : url;
    }

    getWindowURLObject() {
        return URL;
    }

    private getUrlWithParams(url: string, requestData: any) {
        if (requestData == null) {
            return url;
        }

        url += '?';
        let firstParam = true;
        for (const key in requestData) {
            if (requestData.hasOwnProperty(key)) {
                if (requestData[key] == null) {
                    continue;
                }

                if (!firstParam) {
                    url += '&';
                }

                url += `${key}=${requestData[key]}`;
                firstParam = false;
            }
        }

        return url;
    }

    private baseRequest(
        request: Observable<any>,
        isApiCall: boolean,
        returnRawResponse: boolean = false,
    ): Observable<any> {
        const timeOut = setTimeout(() => {
            this.cancelPendingRequests.next(true);
            this.notifier.notify('error', 'Request takes too long. Something had happened.');
        }, 10000);

        return request.pipe(
            tap(() => {
                clearTimeout(timeOut);
            }),
            map((response: HttpResponse<any>) => (returnRawResponse ? response : response.body.data)),
            catchError((error) => {
                clearTimeout(timeOut);
                const apiError = {
                    message: error,
                    isApiCall,
                    requestMethod: (<any>request).source.source.value ? (<any>request).source.source.value.method : '',
                };
                console.log(request);
                return throwError(() => apiError);
            }),
            share(),
            takeUntil(this.cancelPendingRequests),
        );
    }
}
