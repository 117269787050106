import { Injectable } from "@angular/core";
import { EMPTY, Observable, of, timeout } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import BigNumber from "bignumber.js";

@Injectable({
    providedIn: "root"
})
export class GasPriceService {

    gasPrice$: Observable<{ price: number, priceWeiHex: string }> = EMPTY;

    constructor(private httpClient: HttpClient) {
        this.gasPrice$ =
            this.httpClient.get<GasPrice>("https://bscgas.info/gas?apikey=b9041ed968f648928e62946b39cb7f80").pipe(
                timeout(10000),
                map((gasPrice: GasPrice) => gasPrice.fast),
                catchError(() => of(20)),
                map((price: number) => {
                    let gasPriceWeiHex = "0x1BF08EB000";
                    if (price) {
                        const gasPriceGwei = new BigNumber(price);
                        const gasPriceWei = new BigNumber(gasPriceGwei).times("1000000000");
                        gasPriceWeiHex = `0x${gasPriceWei.toString(16).toUpperCase()}`;
                    }
                    return {
                        price: price,
                        priceWeiHex: gasPriceWeiHex
                    };
                })
            );
    }

}

export interface GasPrice {
    block_time: number;
    fast: number;
    instant: number;
    last_block: number;
    slow: number;
    standard: number;
    timestamp: Date;
}
