import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobeState } from './globe-state';

@Injectable({ providedIn: 'root' })
export class GlobeStateService {
    private stateSubject = new BehaviorSubject<GlobeState>(GlobeState.Loading);
    private currentState = this.stateSubject.asObservable();

    setState(state: GlobeState): void {
        this.stateSubject.next(state);
    }

    getState(): Observable<GlobeState> {
        return this.currentState;
    }
}
