import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminPanelComponent } from './admin-panel.component';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { AttributesInputComponent } from '../shared/multiselect-input/multiselect-input.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ErthaLandplotPriceCalculationStrategy } from './price-calculation-strategies/strategies/ertha-landplot-price-calculation.strategy';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MatChipsModule,
        MatFormFieldModule,
        MatRadioModule,
        MatButtonModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatTableModule,
        MatAutocompleteModule,
        SharedModule,
        MatTabsModule,
    ],
    declarations: [AdminPanelComponent, AttributesInputComponent],
    providers: [ErthaLandplotPriceCalculationStrategy],
    exports: [AdminPanelComponent],
})
export class AdminPanelModule {}
