import { Injectable } from '@angular/core';
import { Store } from '@ng-state/store';
import { from, lastValueFrom, Observable, Subject } from 'rxjs';
import { switchMap, take, tap, withLatestFrom, startWith } from 'rxjs/operators';
import { RootState, MarketplaceState } from '../../state';
import { LoggedInUser } from '../models/logged-in-user.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NotifierService } from 'angular-notifier';
import { GoogleAuthProvider, FacebookAuthProvider, UserCredential, AdditionalUserInfo } from '@firebase/auth';
import { TokenService } from './token.service';
import { UserRole } from '../enums/user-role';
import { User } from '../web-api/api-contract';

@Injectable({ providedIn: 'root' })
export class AuthService {
    refreshTokenUrl = 'auth/refresh-token';
    private tokenFromStorageLoaded = false;
    private userCreated$ = new Subject<boolean>();

    constructor(
        private store: Store<MarketplaceState>,
        private afAuth: AngularFireAuth,
        private notifier: NotifierService,
        private erthaApi: TokenService,
    ) {
        const authState$ = this.afAuth.authState;

        this.userCreated$
            .pipe(
                startWith(true),
                switchMap(() =>
                    authState$.pipe(
                        switchMap((user) => {
                            if (user) {
                                return this.erthaApi.getUserDetails({ username: user.email! });
                            } else {
                                return from(Promise.resolve(null));
                            }
                        }),
                        withLatestFrom(authState$),
                    ),
                ),
            )
            .subscribe(async ([user, fbUser]) => {
                if (user && fbUser) {
                    const token = await fbUser.getIdToken();
                    this.saveLoggedInUser({
                        token,
                        refreshToken: fbUser.refreshToken,
                        name: `${user.firstName} ${user.lastName}`,
                        profilePicture: user.profilePicture,
                        role: user.role,
                        isEmailVerified: fbUser.emailVerified,
                    });
                } else {
                    this.saveLoggedInUser({} as any);
                }
            });
    }

    isLoggedIn(roles?: UserRole[]): Observable<boolean> {
        return this.store.select<LoggedInUser>(['root', 'user']).map((state) => {
            let dataFromStorage: { token: any } | undefined;
            if (!state.token && !this.tokenFromStorageLoaded) {
                this.store
                    .select<LoggedInUser>(['root', 'user'])
                    .storage.load({}, true)
                    .pipe(take(1))
                    .subscribe((item) => {
                        this.tokenFromStorageLoaded = true;
                        dataFromStorage = JSON.parse(item.data);
                    });
            }

            return (
                (!!state.token || !!dataFromStorage?.token) &&
                (!roles || roles.findIndex((role) => state.role === role) !== -1)
            );
        });
    }

    get getAuthToken(): Observable<string | undefined> {
        return this.store.select<LoggedInUser>(['root', 'user']).map((state) => state.token);
    }

    get getUserData(): Observable<LoggedInUser | undefined> {
        return this.store.select<RootState>(['root']).map((state) => state.user);
    }

    async signIn(username: string, password: string) {
        return await this.loginActionWrapper(async () => {
            await this.afAuth.signInWithEmailAndPassword(username, password);
        });
    }

    async loginWithGoogle() {
        return await this.loginActionWrapper(async () => {
            let provider = new GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            const credentials = await this.afAuth.signInWithPopup(provider);
            await this.tryCreateUser(credentials as any);
        });
    }

    async loginWithFacebook() {
        return await this.loginActionWrapper(async () => {
            let provider = new FacebookAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            const credentials = await this.afAuth.signInWithPopup(provider);
            await this.tryCreateUser(credentials as any);
        });
    }

    async sendVerificationMail() {
        const currentUser = await this.afAuth.currentUser;
        await currentUser?.sendEmailVerification();
    }

    async forgotPassword(passwordResetEmail: string) {
        await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
        this.notifier.notify('info', 'Password reset email has been sent.');
    }

    async register(userData: Partial<User> & { invite: string; password: string }) {
        await lastValueFrom(
            this.erthaApi.register({
                email: userData.email!,
                password: userData.password,
                firstName: userData.firstName!,
                lastName: userData.lastName!,
                profilePicture: userData.profilePicture!,
                invite: userData.invite,
            }),
        );

        return true;
    }

    private async tryCreateUser(credentials: UserCredential & { additionalUserInfo: AdditionalUserInfo }) {
        if (credentials!.additionalUserInfo!.isNewUser) {
            await lastValueFrom(
                this.erthaApi.tryCreateUser({
                    email: credentials!.user!.email!,
                    fbId: credentials!.user!.uid,
                    firstName: credentials!.user!.displayName?.split(' ')[0],
                    lastName: credentials!.user!.displayName?.split(' ')[1],
                    profilePicture: credentials!.user!.photoURL!,
                }),
            );
        }
    }

    private async loginActionWrapper(action: () => Promise<void>): Promise<boolean> {
        try {
            await action();
            this.userCreated$.next(true);
            return true;
        } catch (e) {
            this.notifier.notify('error', 'Invalid username or password');
            return false;
        }
    }

    async signOut() {
        await this.afAuth.signOut();
    }

    private saveLoggedInUser(user: LoggedInUser) {
        this.store.select<RootState>(['root']).update((state) => {
            state.user = user;
        });

        this.store.select<RootState>(['root', 'user']).storage.save();
    }

    refreshToken() {
        const user$ = this.afAuth.authState.pipe(
            tap((user) => {
                if (!user) {
                    throw new Error('User is not logged in');
                }
            }),
        );
        return user$.pipe(
            switchMap((user) => from(user!.getIdToken())),
            withLatestFrom(user$),
            tap(([token, user]) => {
                this.store.select<RootState>(['root']).update((state) => {
                    state.user!.token = token;
                    state.user!.refreshToken = user?.refreshToken!;
                });

                this.store.select<RootState>(['root', 'user']).storage.save();
            }),
        );
    }
}
