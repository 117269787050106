import { IconMap } from './icon-map.type';

const iconPath = 'assets/img/shared/icons';

export const sharedIcons: IconMap = {
    // Currency
    ethereum: `${iconPath}/eth-logo.svg`,

    // General
    heart: `${iconPath}/heart.svg`,
    filter: `${iconPath}/filter.svg`,
    'down-arrow': `${iconPath}/down-arrow.svg`,
    search: `${iconPath}/search.svg`,
    cart: `${iconPath}/cart.svg`,
    user: `${iconPath}/user.svg`,
    bell: `${iconPath}/bell.svg`,
    'two-by-two-grid': `${iconPath}/2by2.svg`,
    'three-by-three-grid': `${iconPath}/3by3.svg`,

    // Social
    twitter: `${iconPath}/twitter.svg`,
    facebook: `${iconPath}/facebook.svg`,
    youtube: `${iconPath}/youtube.svg`,
    telegram: `${iconPath}/telegram.svg`,
    medium: `${iconPath}/medium.svg`,
    discord: `${iconPath}/discord.svg`,
    linkedin: `${iconPath}/linkedin.svg`,
    instagram: `${iconPath}/instagram.svg`,
};
