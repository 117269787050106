import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { MarketplaceType } from 'src/app/infrastructure/enums/marketplace-type';
import { AuthService } from 'src/app/infrastructure/services/auth.service';
import { CartService } from 'src/app/infrastructure/services/cart.service';
import { TokenDataService } from 'src/app/infrastructure/services/token-data.service';
import { TokenResponseModel } from 'src/app/infrastructure/web-api/api-contract';
import { ImageHelpers } from '../../infrastructure/helpers/image.helpers';

@Component({
    selector: 'fe-sell-item',
    templateUrl: './sell-item.component.html',
    styleUrls: ['./sell-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SellItemComponent {
    @Input()
    id!: string;

    @Input()
    backgroundImage!: string;

    @Input()
    purchaseInfoTitle!: string;

    @Input()
    price!: number;

    @Input()
    discountInPercent!: number;

    @Input()
    isSmall = false;

    @Input()
    item!: TokenResponseModel;

    get defaultImage() {
        return ImageHelpers.getDefaultImage();
    }

    constructor(private router: Router,
                private route: ActivatedRoute,
                private cartService: CartService, 
                private appService: AppService, 
                public authService: AuthService, 
                private tokenDataService: TokenDataService) {}

    isTokenInCart(tokenId: string) {
        return this.cartService.isTokenInCart(tokenId);
    }

    onTokenEditClick(NFTTokenId: number) {
        this.appService.onTokenEdit.next(NFTTokenId);
    }

    removeFromCart(tokenId: string) {
        this.cartService.removeToken(tokenId);
    }

    addToCart(token: TokenResponseModel) {
        const purchaseInfo = this.tokenDataService.getPurchaseInfo(token);

        this.cartService.addToken({
            _id: token._id,
            NFTTokenId: token.NFTTokenId,
            name: token.name || '',
            price: token.price,
            discountInPercent: token.discountInPercent,
            isReserved: token.isReserved,
            backgroundImage: token.image,
            marketplaceType: token.marketplaceType as MarketplaceType,
            purchaseInfoTitle: purchaseInfo.title,
            purchaseInfo: purchaseInfo.info,
        });
    }

    onItemSelected() {
        // Temporarily disabled until it is completed.
        // this.router.navigate(
        //     ['./item'],
        //     { 
        //         relativeTo: this.route,
        //         queryParams: {
        //             id: this.item._id
        //         }
        //     }
        // );
    }
}
