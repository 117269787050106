import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class WebApiDataService {
    private pendingRequests = 0;

    get pendingRequestsCount() {
        return this.pendingRequests;
    }

    increaseRequestsCount() {
        this.pendingRequests++;
    }

    decreaseRequestsCount() {
        if (this.pendingRequests > 0) {
            this.pendingRequests--;
        }
    }

    clear() {
        this.pendingRequests = 0;
    }
}
