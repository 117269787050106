import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'fe-cart-summary',
    styleUrls: ['./cart-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './cart-summary.component.html'
})
export class CartSummaryComponent {

    constructor(private router: Router) {
    }

    navigateTo(path: string) {
        this.router.navigateByUrl(path);
    }

    isActive(path: string): string {
        console.log('path ', this.router.url);
        let isCurrentRoute = this.router.url === path;
        return isCurrentRoute ? 'active' : '';
    }
}
