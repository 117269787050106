import { HasStore, InjectStore } from '@ng-state/store';
import { filter, Observable, startWith } from 'rxjs';
import { Classifier } from '../../infrastructure/web-api/api-contract';
import { AdminPanelState } from '../models/state.model';
import { TokenModel } from '../models/token.model';

@InjectStore('adminPanel')
export class AdminPanelStateActions extends HasStore<AdminPanelState> {
    set token(token: TokenModel) {
        this.store.update((state) => {
            state.token = token;
        });
    }

    set isContentVisible(isOpened: boolean) {
        this.store.update((state) => {
            state.isOpened = isOpened;
        });
    }

    set tokenPrice(price: number) {
        this.store.update((state) => {
            state.token.price = price;
        });
    }

    setImage(fileName: string) {
        this.store.update((state) => {
            state.token.image = fileName;
        });
    }

    removeImage() {
        this.store.update((state) => {
            state.token.image = '';
        });
    }

    setFile(fileName: string) {
        this.store.update((state) => {
            state.multiTokenUpload.file = fileName;
        });
    }

    removeFile() {
        this.store.update((state) => {
            state.multiTokenUpload.file = '';
        });
    }

    addAttribute() {
        this.store.update((state) => {
            if (!state.attributesInput.type) {
                return;
            }

            if (!state.token.attributes) {
                state.token.attributes = [];
            }

            state.token.attributes.push(state.attributesInput);
            state.attributesInput = {
                name: '',
                measureUnits: '',
                type: '',
                value: '',
                metadata: {},
            };
        });
    }

    removeAttribute(index: number) {
        this.store.update((state) => {
            state.token.attributes.splice(index, 1);
        });
    }

    addTokenId(id: string) {
        this.store.update((state) => {
            state.token._id = id;
        });
    }

    getClassifierByType(type: string): Observable<Classifier[]> {
        return this.store.select(['classifiers']).pipe(
            filter((classifier) => classifier.type === type),
            startWith([]),
        );
    }
}
