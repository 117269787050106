import { IconMap } from 'src/app/shared/icons/icon-map.type';
import { erthaIcons, moonIcons, spellfireIcons } from 'src/app/shared/icons/theme-icons';
import { MarketplaceType } from '../app/infrastructure/enums/marketplace-type';
import { TokenResourceKey } from '../app/infrastructure/services/ertha/models/token';

export interface ThemeSetting {
    readonly googleTagManagerToken: string;
    readonly captchaToken: string;
    readonly resources: TokenResourceKey[];
    readonly priceModifier: number;
    readonly priceCoefficients: { [key in TokenResourceKey]: number };
    readonly marketplaceType: MarketplaceType;
    readonly icons: IconMap;
}

export const Themes: { [key in MarketplaceType]: ThemeSetting } = {
    ertha: <ThemeSetting>{
        marketplaceType: MarketplaceType.ertha,
        captchaToken: '6LcbzyMeAAAAADBukYWNEYYjnaUERdz1dMWhTP_G',
        googleTagManagerToken: 'GTM-T49BGDW',
        resources: [
            TokenResourceKey.portals,
            TokenResourceKey.crops,
            TokenResourceKey.fruit,
            TokenResourceKey.minerals,
            TokenResourceKey.rawMeat,
            TokenResourceKey.titanium,
            TokenResourceKey.fish,
            TokenResourceKey.iron,
            TokenResourceKey.oil,
            TokenResourceKey.stone,
            TokenResourceKey.wood,
            TokenResourceKey.airport,
            TokenResourceKey.port,
            TokenResourceKey.volcano,
            TokenResourceKey.mystery,
        ],
        priceModifier: 1.2,
        priceCoefficients: {
            portals: 10000,
            crops: 100,
            fruit: 100,
            minerals: 500,
            rawMeat: 100,
            titanium: 500,
            fish: 100,
            iron: 200,
            oil: 500,
            stone: 100,
            wood: 100,
            airport: 1500,
            port: 1000,
            volcano: 2000,
            mystery: 500,
        },
        icons: erthaIcons,
    },
    moon: <ThemeSetting>{
        marketplaceType: MarketplaceType.moon,
        captchaToken: '6LfxwpweAAAAAMVGXZEZuTcYDgR-_FXUNjO64w6l',
        googleTagManagerToken: 'GTM-PMVXLL7',
        resources: [
            TokenResourceKey.portals,
            TokenResourceKey.iron,
            TokenResourceKey.stone,
            TokenResourceKey.titanium,
            TokenResourceKey.water,
            TokenResourceKey.helium3,
            TokenResourceKey.thorium,
            TokenResourceKey.regolith,
            TokenResourceKey.mystery,
            TokenResourceKey.crystal,
        ],
        priceModifier: 1,
        priceCoefficients: {
            portals: 10000,
            iron: 200,
            stone: 100,
            titanium: 100,
            water: 200,
            helium3: 300,
            thorium: 200,
            regolith: 100,
            mystery: 2000,
            crystal: 5000,
        },
        icons: moonIcons,
    },
    spellfire: <ThemeSetting>{
        marketplaceType: MarketplaceType.spellfire,
        icons: spellfireIcons,
    }
};
