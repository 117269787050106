<mat-form-field class="chip-list" [class.dense]="dense">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-list #chipList>
        <mat-chip
            class="blue dense"
            *ngFor="let item of items; let i = index; trackBy: trackByFn"
            (removed)="remove(i)"
        >
            {{ item }}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
        <input
            matInput
            placeholder="New Metadata Item"
            #fruitInput
            [formControl]="metadataCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
        />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let item of filteredMetadataKeys | async" [value]="item">
            {{ item }}
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="metadataCtrl?.errors && (metadataCtrl?.errors)!['required'] && metadataCtrl?.touched">
        Field is required
    </mat-error>
</mat-form-field>
