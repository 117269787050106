import { Message } from '@ng-state/store';

export class ToggleLoading extends Message {
    constructor(data: ISpinnerMessage) {
        super('toggleLoading', data);
    }
}

export interface ISpinnerMessage {
    start: boolean;
    message: string | undefined | null;
}
