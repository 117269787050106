<fe-cart-summary></fe-cart-summary>
<div class="cart-items-header">
    <h1>Cart</h1>
</div>
<div class="cart-items-container">
    <div class="cart-item-labels">
        <span class="cart-item-label">Item</span>
        <span class="cart-item-label">Price</span>
        <span class="cart-item-label">Discount</span>
        <span class="cart-item-label">Purchase Info</span>
        <span class="cart-item-label"></span>
    </div>
    <div *ngIf="actions.state?.tokens?.length === 0" class="no-items">Cart is empty.</div>
    <div class="cart-items">
        <fe-cart-item 
            *ngFor="let item of actions.state!.tokens; trackBy: trackByFn"
            [item]="item"
            (removeItem)="removeFromCart($event)">      
        </fe-cart-item>
    </div>
    <div class="cart-actions">
        <div class="cart-total">
            <div
                class="full-price"
                *ngIf="!actions.state?.priceWithBountyDiscount || actions.state?.priceWithBountyDiscount?.toString() === '0'; else priceWithDiscount">
                Total: {{ actions.cartTotalPrice | currency: 'USD' }}
                <span class="bnb-total">({{ actions.cartTotalPrice | bnbPrice | async }} BNB)</span>
            </div>
        </div>
        <button fe-button [disabled]="(actions.state?.tokens)!.length === 0" (click)="createOrder()">PLACE ORDER</button>
    </div>
    <div class="bounty-discount">
        <form [formGroup]="form">
            <p>You can add referral account or alias and get additional discount</p>
            <div class="discount-form-input">
                <input feInput formControlName="bountyDiscountAccount" type="text" placeholder="Account No. or Alias" />
                <button fe-button
                    class="warning-button"
                    [disabled]="(actions.state?.tokens)!.length === 0 && actions.state?.priceWithBountyDiscount"
                    (click)="getBountyDiscountPrice(actions.cartTotalPrice)">
                    APPLY DISCOUNT
                </button>
            </div>
        </form>
    </div>
    <ng-template #priceWithDiscount>
        <div>
            Total:
            <span class="old-price">{{ actions.cartTotalPrice | currency: 'USD' }}</span>
            <span class="new-price">{{ actions.state?.priceWithBountyDiscount! | currency: 'USD' }}</span>
            (<span class="bnb-total">{{ actions.state?.priceWithBountyDiscount! | bnbPrice | async }} BNB</span>)
        </div>
        <div class="referral-discount-label">Referral discount applied</div>
    </ng-template>
</div>

