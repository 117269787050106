import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenService } from '../../infrastructure/services/token.service';

@Pipe({
    name: 'bnbPrice',
})
export class BnbPricePipe implements PipeTransform {
    constructor(private erthaApi: TokenService) {}

    transform(usdPrice: number): Observable<string> {
        return this.erthaApi.rates$.pipe(map((rates) => (usdPrice / rates.bnbUSDRate).toFixed(4)));
    }
}
