import { Component } from '@angular/core';

@Component({
  selector: 'app-ertha-footer',
  templateUrl: './ertha-footer.component.html',
  styleUrls: ['./ertha-footer.component.scss']
})
export class ErthaFooterComponent {
  contractAddress = 'Ox62823659009F9f902222058878f89437425eb261';
}
