import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentState, HasStateActions } from '@ng-state/store';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppStateActions } from './actions/app.actions';
import { AppService } from './app.service';
import { MarketplaceType } from './infrastructure/enums/marketplace-type';
import { AuthService } from './infrastructure/services/auth.service';
import { CartService } from './infrastructure/services/cart.service';
import { DeviceDetectionService } from './infrastructure/services/device-detection/device-detection.service';
import { TokenService } from './infrastructure/services/token.service';
import { StatusService } from './infrastructure/services/ertha/status.service';
import { Classifier } from './infrastructure/web-api/api-contract';
import { IconRegistryService } from './shared/services/icon-registry-service';

@ComponentState(AppStateActions)
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends HasStateActions<AppStateActions> implements OnInit, OnDestroy {
    private destroy$ = new Subject<any>();
    public theme: MarketplaceType;

    constructor(
        cd: ChangeDetectorRef,
        private statusService: StatusService,
        private cartService: CartService,
        private router: Router,
        public dd: DeviceDetectionService,
        public erthaApi: TokenService,
        public appService: AppService,
        public authService: AuthService,
        iconRegistryService: IconRegistryService,
    ) {
        super(cd);
        iconRegistryService.initialize(environment.theme.icons);
        this.theme = environment.theme.marketplaceType as MarketplaceType;
    }

    override ngOnInit(): void {
        this.cartService.init();
        this.statusService.connect();

        this.getClassifiers();

        this.appService.refreshClassifiers.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.getClassifiers(true);
        });
    }

    override ngOnDestroy(): void {
        this.cartService.destroy();
        this.statusService.disconnect();
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    isActiveRoute(url: string): boolean {
        return this.router.url.toString().indexOf(url) > -1;
    }

    private getClassifiers(forceFetch: boolean = false) {
        if (!forceFetch && this.actions.state?.classifiers && this.actions.state?.classifiers.length > 0) {
            return;
        }

        this.erthaApi.getClassifiers().subscribe((classifiers: Classifier[]) => {
            this.actions.classifiers = classifiers;
        });
    }
}
