import { IconMap } from "./icon-map.type";

const erthaIconPath = 'assets/img/ertha/icons';
export const erthaIcons: IconMap = {
    logo: 'assets/img/ertha/logo.svg',
};

const spellfireIconPath = 'assets/img/spellfire/icons';
export const spellfireIcons: IconMap = {};

const moonIconPath = 'assets/img/moon/icons';
export const moonIcons: IconMap = {
    logo: 'assets/img/moon/logo.svg',
    search: `${moonIconPath}/search.svg`,
};