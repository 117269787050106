import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CartItem } from '../models/cart-item';

@Component({
  selector: 'fe-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent {

  @Input()
  item!: CartItem;

  @Output()
  removeItem = new EventEmitter<string>();

  removeFromCart(): void {
	  this.removeItem.emit(this.item._id);
  }
}
