import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

declare const grecaptcha: any;

@Injectable({
    providedIn: 'root',
})
export class CaptchaService {
    getToken(action: string): Observable<string> {
        return new Observable((observer) => {
            grecaptcha.ready(() => {
                grecaptcha.execute(environment.captchaToken, { action: action }).then((token: any) => {
                    observer.next(token);
                    observer.complete();
                });
            });
        });
    }
}
