import { animate, group, query, sequence, state, style, transition, trigger } from '@angular/animations';

export const cartAnimations = [
    trigger('sideCard', [
        state('void', style({ opacity: 0, height: 0, marginBottom: 0 })),
        state('*', style({ opacity: 1, height: '*', marginBottom: '*' })),
        transition('void => *', group([
            animate('300ms ease-out'),
            query('er-marketplace-card > .content', [style({ overflow: 'hidden', height: 0 })]),
        ])),
        transition('* => void', group([
            animate('300ms ease-out', style({ opacity: 0, height: 0, marginBottom: 0 })),
            query('er-marketplace-card >  .content', [style({ overflow: 'hidden', height: 0 })]),
        ]))
    ]),
    trigger('actionButtons', [
        state('hidden', style({ opacity: 0, height: 0 })),
        state('visible', style({ opacity: 1, height: 32 })),
        transition('hidden => visible', sequence([
            animate('200ms ease-out', style({ opacity: 0, height: 32 })),
            animate('200ms ease-out', style({ opacity: 1, height: 32 }))
        ])),
        transition('visible => hidden', sequence([
            animate('200ms ease-out', style({ opacity: 0, height: 32 })),
            animate('100ms ease-out', style({ opacity: 0, height: 0 }))
        ]))
    ])
];
