import { Injectable } from '@angular/core';
import { Dispatcher } from '@ng-state/store';
import { Subscription } from 'rxjs';
import { ToggleLoading } from '../messages/toggle-loading.message';

@Injectable({
    providedIn: 'root',
})
export class ComponentService {
    constructor(private dispatcher: Dispatcher) {}

    spinner = {
        show: (message?: string) => {
            this.trigger(new ToggleLoading({ start: true, message: message }));
        },
        hide: (message?: string) => {
            this.trigger(new ToggleLoading({ start: false, message: message }));
        },
    };

    on(message: any, action: (payload: any) => void): Subscription {
        return this.dispatcher.subscribe(message, action);
    }

    private trigger(message: any) {
        this.dispatcher.publish(message);
    }
}
