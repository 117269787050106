import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MarketplaceType } from '../infrastructure/enums/marketplace-type';
import { AuthService } from '../infrastructure/services/auth.service';

@Component({
    selector: 'fe-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    public theme: MarketplaceType;

    constructor(public authService: AuthService) {
        this.theme = environment.theme.marketplaceType as MarketplaceType;
    }
}
