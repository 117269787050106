<fe-spinner></fe-spinner>
<div class="notifications-holder">
    <notifier-container></notifier-container>
</div>

<fe-panel *ngIf="authService.isLoggedIn(['admin']) | async" class="admin-bar" title="Admin Panel" >
    <fe-admin-panel></fe-admin-panel>
</fe-panel>

<fe-header></fe-header>

<div class="page-content">
    <!--<div class="cart-holder">
        fe-cart-icon></fe-cart-icon
    </div>
    <div class="top-menu">
        <a [routerLink]="['/marketplace']" [routerLinkActive]="['selected']"><mat-icon>list</mat-icon> Items</a>
        <a [routerLink]="['/globe']" [routerLinkActive]="['selected']"><mat-icon>public</mat-icon> Ertha</a>
        <a [routerLink]="['/my-tokens']" [routerLinkActive]="['selected']"><mat-icon>wallet</mat-icon> My Tokens</a>
        <a [routerLink]="['/auth']" [routerLinkActive]="['selected']"><mat-icon>login</mat-icon> Sign In</a>
    </div>-->
    <div class="separator"></div>
    <div class="content" [class.remove-margin]="isActiveRoute('/globe')">
        <router-outlet></router-outlet>
    </div>
    <div class="separator"></div>
</div>
<footer [ngSwitch]="theme">
    <app-ertha-footer *ngSwitchCase="'ertha'"></app-ertha-footer>
    <app-moon-footer *ngSwitchCase="'moon'"></app-moon-footer>
    <app-spellfire-footer *ngSwitchCase="'spellfire'"></app-spellfire-footer>
</footer>
