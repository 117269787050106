import { Component, ElementRef, Input, Renderer2, ViewChild, AfterViewInit } from '@angular/core';

@Component({
    selector: 'fe-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements AfterViewInit {
    @Input() title!: string;
    @Input() watchChanges?: string;
    @ViewChild('content') content: ElementRef<HTMLInputElement>;

    public openState: boolean = false;

    constructor(private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.render();
    }

    onContentChange(changes: MutationRecord[]) {
        //content has changed, if open re render
        if (this.openState) {
            this.render();
        }
    }

    public toggleState() {
        this.openState = !this.openState;
        this.render();
    }

    private render() {
        var element = this.content.nativeElement;

        if (this.openState) {
            this.renderer.setStyle(element, 'max-height', element.scrollHeight + 'px');
        } else {
            this.renderer.setStyle(element, 'max-height', 0);
        }
    }
}
