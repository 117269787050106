import { Injectable } from '@angular/core';
import { Store } from '@ng-state/store';
import { take } from 'rxjs';
import { Themes } from '../../../../environments/theme';
import { ErthaTokenAttributeType } from '../../../infrastructure/enums/ertha-token-attribute-type';
import { MarketplaceType } from '../../../infrastructure/enums/marketplace-type';
import { TokenAttribute } from '../../../infrastructure/web-api/api-contract';
import { RootState } from '../../../state';
import { TokenModel } from '../../models/token.model';
import { PriceCalculationStrategy } from '../price-calculation.strategy';
import { Land200, Land350, Land500 } from './country-codes';

@Injectable()
export class ErthaLandplotPriceCalculationStrategy extends PriceCalculationStrategy {
    constructor(private store$: Store<RootState>) {
        super();
    }

    calculate(token: TokenModel): number {
        let price: number; // If Hex=water then price $100
        const marketplaceType = this.getMarketplaceType();
        const tt = token;
        const countries =
            token.attributes.filter((attr) => attr.type === ErthaTokenAttributeType.country) ||
            ([] as TokenAttribute[]);

        if (countries.length === 0) {
            price = 100;
        } else {
            const largest = countries.reduce((largest: TokenAttribute, current) => {
                if (!largest) {
                    return current;
                }
                if (largest.value < current.value) {
                    return current;
                }
                return largest;
            }, {} as TokenAttribute);

            price = Math.round(this.getCountryValue(largest.name));
        }

        const cities =
            token.attributes.filter((attr) => attr.type === ErthaTokenAttributeType.city) || ([] as TokenAttribute[]);
        if (cities.length > 0) {
            price = 1000; // If city then 1000$
        }

        let coefficient = Themes[marketplaceType].priceCoefficients as { [index: string]: number };
        let priceModifier = Themes[marketplaceType].priceModifier;

        const resources =
            token.attributes.filter((attr) => attr.type === ErthaTokenAttributeType.resource) ||
            ([] as TokenAttribute[]);

        for (const key of Object.keys(coefficient)) {
            const resource = resources.find((attr) => attr.name === key);
            if (resource) {
                price += (+resource.value * coefficient[key]) / 100;
            }
        }

        price = Math.round(price * priceModifier); // 2022.01 20% price increase
        return price;
    }

    getCountryValue(countryCode: string): number {
        if (Land200.indexOf(countryCode) !== -1) {
            return 200;
        } else if (Land350.indexOf(countryCode) !== -1) {
            return 350;
        } else if (Land500.indexOf(countryCode) !== -1) {
            return 500;
        }
        console.log(`Country with code "${countryCode}" not found`);
        return 100;
    }

    private getMarketplaceType(): keyof typeof MarketplaceType {
        let marketplaceType = 'ertha';

        this.store$
            .select(['root'])
            .pipe(take(1))
            .subscribe((state) => {
                marketplaceType = state.marketplaceType;
            });

        return marketplaceType as MarketplaceType;
    }
}
