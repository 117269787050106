<div class="divider"></div>
<div class="footer-container">
    <div class="section">
        <div class="links">
            <a target="_blank" href="#">WHITEPAPER</a>
            <a target="_blank" href="#">FAQ</a>
            <a target="_blank" href="#">PRIVACY POLICY</a>
            <a target="_blank" href="#">CONTACTS</a>
        </div>
        <div class="socials">
            <a target="_blank" href="#">
                <mat-icon class="icon-color-primary" svgIcon="twitter"></mat-icon>
                <span>6000</span>
            </a>
            <a target="_blank" href="#">
                <mat-icon class="icon-color-primary" svgIcon="telegram"></mat-icon>
                <span>6000</span>
            </a>
            <a target="_blank" href="#">
                <mat-icon class="icon-color-primary" svgIcon="discord"></mat-icon>
                <span>6000</span>
            </a>
            <a target="_blank" href="#">
                <mat-icon class="icon-color-primary" svgIcon="facebook"></mat-icon>
            </a>
            <a target="_blank" href="#">
                <mat-icon class="icon-color-primary" svgIcon="youtube"></mat-icon>
            </a>
            <a target="_blank" href="#">
                <mat-icon class="icon-color-primary" svgIcon="linkedin"></mat-icon>
            </a>
            <a target="_blank" href="#">
                <mat-icon class="icon-color-primary" svgIcon="instagram"></mat-icon>
            </a>
        </div>
        <p class="secondary-text">2021 Spellfire ® · All rights reserved.</p>
    </div>
    <div class="section">
        <div class="contract-address-title">
            <p>SPELLFIRE OFFICIAL CONTRACT ADDRESS</p>
        </div>
        <div class="contract-address">
            <p><span class="accent">BSC: </span>0xd6f28f15a5cafc8d29556393c08177124b88de0d</p>
            <button fe-button class="btn-tertiary">BSCSAN.COM</button>
        </div>
        <div class="contract-address">
            <p><span class="accent">ERC-20: </span>0x3a0b022f32b3191d44e5847da12dc0b63fb07c91</p>
            <button fe-button class="btn-tertiary">ETHERSCAN.IO</button>
        </div>
    </div>
</div>