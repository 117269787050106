import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './cart/cart-items/cart-items.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'marketplace',
        pathMatch: 'full',
    },
    {
        path: 'cart',
        component: CartComponent,
    },
    {
        path: 'orders',
        loadChildren: () => import('./cart/orders-list/orders-list.module').then((m) => m.OrdersListModule),
    },
    {
        path: 'marketplace',
        loadChildren: () => import('./marketplace/marketplace.module').then((m) => m.MarketplaceModule),
    },
    {
        path: 'globe',
        loadChildren: () => import('./globe/globe.module').then((m) => m.GlobeModule),
    },
    {
        path: 'my-tokens',
        loadChildren: () => import('./account-tokens/account-tokens.module').then((m) => m.AccountTokensModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('./sign-in/sign-in.module').then((m) => m.SignInModule),
    },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
