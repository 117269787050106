import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HashService {
    hashChange$: Observable<number>;

    constructor() {
        this.hashChange$ = fromEvent(window, 'hashchange').pipe(
            map(() => parseInt(window.location.hash.replace('#', ''))),
        );
    }

    setHashByCell(cellId: number | string | null): void {
        const hash = cellId !== null && !isNaN(+cellId) ? `#${cellId}` : '';
        history.replaceState(null, '', document.location.pathname + hash);
    }

    getFromHash(): number {
        return parseInt(window.location.hash.replace('#', ''));
    }
}
