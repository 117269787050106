<h4>Add / Edit</h4>
<div class="line-top"></div>
<form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="onSubmit()">
    <div class="flex-row">
        <section formGroupName="token">
            <div class="flex-col space-between top-space">
                <div class="file-select">
                    <fe-file-select
                        [selectedFile]="actions.state?.token?.image || ''"
                        type="image"
                        (onFilesSelected)="onImageChange($event)"
                    ></fe-file-select>
                    <mat-error *ngIf="hasError('image')">Image is required</mat-error>
                </div>
                <mat-slide-toggle formControlName="isForSale">For Sale</mat-slide-toggle>
                <mat-checkbox formControlName="autoPrice">Auto Price</mat-checkbox>

                <div>
                    <mat-label>NFT ID</mat-label>
                    <input feInput type="text" formControlName="NFTTokenId" />
                    <mat-error *ngIf="hasError('NFTTokenId')">Field is required</mat-error>
                </div>

                <div>
                    <mat-label>Name</mat-label>
                    <input feInput type="text" formControlName="name" />
                    <mat-error *ngIf="hasError('name')">Field is required</mat-error>
                </div>
                <div>
                    <div>
                        <div>
                            <mat-label>Price</mat-label>
                            <input feInput type="text" formControlName="price" />
                            <mat-error *ngIf="hasError('price')">{{ getErrorMessage('price') }}</mat-error>
                        </div>
                    </div>
                </div>
                <div>
                    <mat-label>Discount %</mat-label>
                    <input feInput type="text" formControlName="discountInPercent" />
                    <mat-error *ngIf="hasError('discountInPercent')">Field is required</mat-error>
                </div>
                <fe-multiselect-input
                    class="dense full-width"
                    [suggestions]="(getTokenTypeSuggestions('tokenType') | async) || []"
                    formControlName="type"
                    [isFormSubmitted]="isFormSubmitted"
                    label="Type"
                    [dense]="true"
                >
                </fe-multiselect-input>
            </div>
        </section>
        <div class="line-mid"></div>
        <div class="flex-col grow">
            <h5>Attributes</h5>
            <table mat-table [dataSource]="actions.state?.token?.attributes || []" class="full-width">
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let element">{{ element.type }}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>

                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef>Value</th>
                    <td mat-cell *matCellDef="let element">{{ element.value }}</td>
                </ng-container>

                <ng-container matColumnDef="measureUnits">
                    <th mat-header-cell *matHeaderCellDef>Unit</th>
                    <td mat-cell *matCellDef="let element">{{ element.measureUnits }}</td>
                </ng-container>

                <ng-container matColumnDef="metadata">
                    <th mat-header-cell *matHeaderCellDef>Metadata</th>
                    <td mat-cell *matCellDef="let element">{{ getMetaData(element.metadata) }}</td>
                </ng-container>

                <ng-container matColumnDef="remove">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let i = index">
                        <mat-icon (click)="actions.removeAttribute(i)">close</mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="attributeColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: attributeColumns"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell text-center" colspan="9999">There are not attributes</td>
                </tr>
            </table>

            <section formGroupName="attributesInput">
                <div class="flex-row">
                    <div class="grow">
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="type">
                            <mat-option
                                *ngFor="
                                    let item of getClassifierByType('tokenAttributeType') | async;
                                    trackBy: trackByFnClassifier
                                "
                                [value]="item.value"
                            >
                                {{ item.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError('type')">Field is required</mat-error>
                    </div>
                    <div class="grow">
                        <mat-label>Name</mat-label>
                        <input
                            *ngIf="selectedAttributeType === 'city'; else resourceSelector"
                            feInput
                            type="text"
                            formControlName="name"
                        />
                        <ng-template #resourceSelector>
                            <mat-select formControlName="name">
                                <mat-option
                                    *ngFor="
                                        let item of getClassifierByType(selectedAttributeType) | async;
                                        trackBy: trackByFnClassifier
                                    "
                                    [value]="item.value"
                                >
                                    {{ item.name }}
                                </mat-option>
                            </mat-select>
                        </ng-template>
                        <mat-error *ngIf="hasError('name')">Field is required</mat-error>
                    </div>
                    <div class="grow">
                        <mat-label>Value</mat-label>
                        <input feInput type="text" formControlName="value" />
                    </div>
                    <div class="grow">
                        <div class="relative-width">
                            <mat-label>Units</mat-label>
                            <input feInput type="text" formControlName="measureUnits" />
                        </div>
                    </div>
                </div>
                <div class="flex-row baseline">
                    <fe-multiselect-input
                        class="grow"
                        [inputValidatorFn]="attributeMetadataInputValidator"
                        [suggestionExtractorFn]="attributeSuggestionExtractorFn"
                        label="Metadata"
                        [suggestions]="
                            (getMetadataSuggestions('tokenAttributeMetadataType', selectedAttributeType || 'unknown')
                                | async) || []
                        "
                        formControlName="metadata"
                    ></fe-multiselect-input>
                    <button class="float-right" type="button" fe-button (click)="onAddAttributeClick()">
                        Add Attribute
                    </button>
                </div>
            </section>
            <div class="flex-row space-between last">
                <button type="button" fe-button (click)="onNewTokenButtonClick()">New Token</button>
                <button type="submit" fe-button>Save</button>
            </div>
        </div>
    </div>
</form>

<h4>Multiple Upload</h4>
<div class="line-top"></div>
<form [formGroup]="multiTokenForm" (ngSubmit)="onMultiSubmit()">
    <section class="flex-row top-space">
        <div class="file-select">
            <fe-file-select
                [selectedFile]="actions.state?.multiTokenUpload?.file || ''"
                type="file"
                (onFilesSelected)="onFileChange($event)"
            ></fe-file-select>
            <mat-error class="image-error" *ngIf="hasMultiTokenFormError('file')">File is required</mat-error>
        </div>
        <div class="flex-col grow left-space">
            <mat-slide-toggle formControlName="mintToken">Mint token</mat-slide-toggle>

            <div class="flex-row full-width end">
                <div class="full-width">
                    <mat-label>Mint Account</mat-label>
                    <input feInput type="text" formControlName="mintAccount" />
                    <mat-error *ngIf="hasMultiTokenFormError('mintAccount')">Field is required</mat-error>
                </div>
                <button class="flex-item left" type="submit" fe-button>Upload</button>
            </div>
        </div>
    </section>
</form>
