import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import { AdminService } from './admin-panel/admin.service';
import { filter, take } from 'rxjs/operators';
import { UnityGlobeService } from './unity-globe.service';
import { GlobeTileInfoData } from '../../models';
import { HashService } from '../hash.service';
import { TileClickedEvent } from './events/tile-clicked.event';
import { Store } from '@ng-state/store';
import { GlobeState, MarketplaceState } from '../../../state';

@Injectable({
    providedIn: 'root',
})
export class TileSelectionService {
    zIndexMap: { [id: string]: number } = {};
    currentZ = 1;

    private timeoutHandle: any;
    private skipNextChange: boolean = true;

    private store$: Store<GlobeState>;

    constructor(
        private globeService: UnityGlobeService,
        private hashService: HashService,
        store: Store<MarketplaceState>,
    ) {
        this.store$ = store.select(['globe']);

        this.store$
            .pipe(
                filter(() => {
                    if (this.skipNextChange) {
                        this.skipNextChange = false;
                        return false;
                    }
                    return true;
                }),
            )
            .subscribe((state) => {
                const tokenId = state.selectedTiles.length > 0 ? state.selectedTiles[0].NFTTokenId : null;
                this.hashService.setHashByCell(tokenId);
            });
    }

    selectTileCentered(tileId: number): void {
        this.skipNextChange = true;
        this.updateSelectedTiles([]);
        this.globeService.goToCell(tileId, 10, 1000);
        clearTimeout(this.timeoutHandle);
        this.timeoutHandle = setTimeout(() => {
            const center = this.globeService.getCenter();
            this.selectTile({
                tileId: tileId,
                x: center.x,
                y: center.y,
                append: false,
            });
        }, 750);
    }

    selectTile(tileEv: TileClickedEvent): void {
        this.store$.pipe(take(1)).subscribe((state) => {
            let selectedTiles = state.selectedTiles;

            if (selectedTiles.find((t) => t.NFTTokenId === tileEv.tileId)) {
                this.updateSelectedTiles(selectedTiles.filter((t) => t.NFTTokenId !== tileEv.tileId));
            } else {
                this.hashService.setHashByCell(tileEv.tileId);

                const tileX = tileEv.x + 50;
                const tileY = tileEv.y + 10;

                const tileInfo = {
                    uniqueId: Math.random(),
                    x: tileX,
                    y: tileY,
                    NFTTokenId: tileEv.tileId,
                    floatLeft: false,
                };
                if (tileEv.append) {
                    selectedTiles = selectedTiles.concat(tileInfo);
                } else {
                    selectedTiles = [tileInfo];
                    this.zIndexMap = {};
                    this.currentZ = 1;
                }
                this.updateSelectedTiles(selectedTiles);
            }
        });
    }

    closeOnEscape(): void {
        this.store$
            .pipe(
                filter((state) => state.selectedTiles.length > 0),
                take(1),
            )
            .subscribe((state) => {
                this.close(state.selectedTiles[state.selectedTiles.length - 1]);
            });
    }

    close(tileData: GlobeTileInfoData): void {
        this.store$.pipe(take(1)).subscribe((state) => {
            const selectedTiles = state.selectedTiles.filter((td) => td !== tileData);
            this.updateSelectedTiles(selectedTiles);
        });
    }

    closeAll(): void {
        this.updateSelectedTiles([]);
    }

    focusDialog(tileData: GlobeTileInfoData): void {
        this.zIndexMap[tileData.uniqueId!] = this.currentZ;
        this.currentZ++;
    }

    private updateSelectedTiles(selectedTiles: GlobeTileInfoData[]): void {
        this.globeService.highlightCells(
            selectedTiles.map((st) => st.NFTTokenId),
            237,
            50,
            58,
            100,
        );

        this.store$.update((state) => {
            state.selectedTiles = [];
            state.selectedTiles = selectedTiles;
        });
    }
}
