import { HasStore, InjectStore } from '@ng-state/store';
import { PriceHelpers } from '../../infrastructure/helpers/price.helpers';
import { TokenBase } from '../../infrastructure/services/ertha/models/token';
import { CartState } from '../../state';

@InjectStore('cart')
export class CartStateActions extends HasStore<CartState> {
    get orders() {
        return this.store.map((state) => {
            return state.serializable.orders;
        });
    }

    get showOrderWarning() {
        return this.store.map((state) => {
            return state.serializable.showOrderWarning;
        });
    }

    get cartTotalPrice(): number {
        return this.state?.tokens.reduce((total, token) => total + this.getPriceWithDiscount(token), 0) || 0;
    }

    setPriceWithDiscount(price: number) {
        this.store.update((state) => {
            state.priceWithBountyDiscount = price;
        });
    }

    removeOrderRecord(orderId: string): void {
        this.store.update((state) => {
            const index = state.serializable.orders.findIndex((o) => o._id === orderId);
            if (index !== -1) {
                state.serializable.orders.splice(index, 1);
            }
        });
        this.store.select(['serializable']).storage.save();
    }

    hideWarning(): void {
        this.store.update((state) => {
            state.serializable.showOrderWarning = false;
        });
        this.store.select(['serializable']).storage.save();
    }

    completeOrder(orderId: string): void {
        this.store.update((state) => {
            state.serializable.orders.map((order) => {
                if (order._id === orderId) {
                    order.isCompleted = true;
                }
            });
        });
        this.store.select(['serializable']).storage.save();
    }

    private getPriceWithDiscount(token: TokenBase) {
        return !token.discountInPercent
            ? token.price
            : PriceHelpers.getPriceWithDiscount(token.price, token.discountInPercent);
    }
}
