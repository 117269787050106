<div class="cart-item">
    <span class="cart-item-property item-name-container">
        <img [src]="item.backgroundImage"/> 
        <span class="item-name">{{ item.name }}</span>
    </span>
            
    <span class="cart-item-property">
        <span>{{ item.price | currency: 'USD' }}</span>
        <span>({{ item.price | bnbPrice | async }} BNB)</span>
    </span>
    <span class="cart-item-property">{{ item.discountInPercent }}</span>
    <span class="cart-item-property">{{ item.purchaseInfo }}</span>
    <span class="cart-item-property remove-from-cart">
        <button fe-button (click)="removeFromCart()">X</button>  
    </span>
</div> 
