import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileSelectType } from './file-select-type';

@Component({
    selector: 'fe-file-select',
    styleUrls: ['./file-select.component.scss'],
    template: `
        <div class="image-holder" [class.file]="!isImage">
            <mat-icon *ngIf="!selectedFile && selectedFiles.length === 0; else imageHolder">{{ icon }}</mat-icon>
        </div>
        <input
            class="file-select"
            type="file"
            accept="image/png, image/jpeg, image/jpg, .json"
            (mouseenter)="hovered = true"
            (mouseleave)="hovered = false"
            (change)="onChange($event)"
        />
        <ng-template #imageHolder>
            <img *ngIf="isImage" [src]="selectedFiles.length > 0 ? getPreviewFile(selectedFiles[0]) : selectedFile" />
            <div *ngIf="!isImage">{{ selectedFiles.length > 0 ? selectedFiles[0].name : selectedFile }}</div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSelectComponent {
    public text: string;
    public icon: string;
    public isImage: boolean;

    @HostBinding('class.disabled')
    @Input()
    disabled: boolean;

    @Input()
    set type(value: FileSelectType) {
        switch (value) {
            case 'image':
                this.isImage = true;
                this.text = 'Select image';
                this.icon = 'image';
                break;
            case 'file':
                this.isImage = false;
                this.text = 'Select file';
                this.icon = 'file_upload';
                break;
        }
    }

    _selectedFile: string = '';
    @Input()
    set selectedFile(value: string) {
        if (this.selectedFiles.length === 0 || value !== this.selectedFiles[0].name) {
            this._selectedFile = value;
            this.selectedFiles = [];
        }
    }
    get selectedFile(): string {
        return this._selectedFile;
    }

    @Output() onFilesSelected: EventEmitter<FileList> = new EventEmitter<FileList>();

    @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;

    hovered: boolean;
    selectedFiles: File[] = [];

    constructor(private cd: ChangeDetectorRef, private sanitizer: DomSanitizer) {}

    getPreviewFile(file: File) {
        return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
    }

    onChange(ev: Event): void {
        const input = ev.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            this.onFilesSelected.emit(input.files!);
            this.selectedFiles = Array.from(input.files!);
            input.files = null;
            input.value = '';
            this.cd.markForCheck();
        }
    }
}
