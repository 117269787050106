<div class="footer-accent">
    <div class="background"></div>
    <div class="triangle"></div>
</div>
<div class="footer-container">
    <div class="section">
        <mat-icon class="footer-logo icon-color-secondary" svgIcon="logo"></mat-icon>
        <p>All rights reserved @ Ertha 2022</p>
    </div>
    <div class="section links">
        <!-- <a target="_blank">The Ertha Game</a>
        <a target="_blank">Doctrine</a>
        <a target="_blank">Economic Paper</a>
        <a target="_blank">Token release schedule</a> -->
    </div>
    <div class="section links">
        <!-- <a target="_blank">Roadmap</a>
        <a target="_blank">Alpha version</a>
        <a target="_blank">Creators</a>
        <a target="_blank">Ertha landowners benefits</a> -->
    </div>
    <div class="section">
        <div class="contract-address">
            <p class="contract-title">ERTHA official contract address:</p>
            <p>{{ contractAddress }}</p>
            <button fe-button class="btn-tertiary" [copy-clipboard]="contractAddress">copy contract address</button>
        </div>
        <div class="socials">
            <a target="_blank" href="https://twitter.com/ErthaGame">
                <mat-icon class="icon-color-secondary" svgIcon="twitter"></mat-icon>
            </a>
            <a target="_blank" href="https://www.facebook.com/Ertha-1523173454647823">
                <mat-icon class="icon-color-secondary" svgIcon="facebook"></mat-icon>
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCHiXL-GSDqd9jIa1hsr20Kw">
                <mat-icon class="icon-color-secondary" svgIcon="youtube"></mat-icon>
            </a>
            <a target="_blank" href="https://t.me/Erthium">
                <mat-icon class="icon-color-secondary" svgIcon="telegram"></mat-icon>
            </a>
            <a target="_blank" href="https://erthium.medium.com">
                <mat-icon class="icon-color-secondary" svgIcon="medium"></mat-icon>
            </a>
            <a target="_blank" href="https://discord.com/invite/ertha">
                <mat-icon class="icon-color-secondary" svgIcon="discord"></mat-icon>
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/erthium">
                <mat-icon class="icon-color-secondary" svgIcon="linkedin"></mat-icon>
            </a>
        </div>
    </div>
</div>