import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvertToBnbPipe } from './convert-to-bnb.pipe';
import { ConvertToDollarPipe } from './convert-to-dollar.pipe';
import { BnbPricePipe } from './bnb-price.pipe';
import { IsOrderExpiredPipe } from './is-order-expired.pipe';
import { ToTokenNamePipe } from './to-token-name.pipe';
import { PriceWithDiscountPrice } from './price-discount.pipe';

const declarations = [
    ConvertToBnbPipe,
    ConvertToDollarPipe,
    BnbPricePipe,
    IsOrderExpiredPipe,
    ToTokenNamePipe,
    PriceWithDiscountPrice,
];

@NgModule({
    declarations,
    exports: declarations,
    imports: [CommonModule],
})
export class SharedPipesModule {}
