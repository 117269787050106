import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentService } from '../../infrastructure/services/component.service';
import { WebApiDataService } from '../../infrastructure/web-api/web-api-data.service';
import { ISpinnerMessage, ToggleLoading } from '../../infrastructure/messages/toggle-loading.message';
import { PlatformService } from '../../infrastructure/services/platform.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'fe-spinner',
    templateUrl: './spinner.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnInit {
    static showTimeout = 2000;
    static hideTimeout = 500;
    static waitTillHideTimeout = 200;
    static hideTaskInterval = 1000;

    showSpinner: boolean = false;
    addToDom: boolean = false;
    initialRequests: boolean = true;
    hideCheckIntervalId?: NodeJS.Timer;
    message: string | undefined | null = '';

    loaderToggleSubscription?: Subscription;

    private showTimeoutId: any;

    constructor(
        private cd: ChangeDetectorRef,
        private webApiData: WebApiDataService,
        private componentService: ComponentService,
        private platform: PlatformService,
        private spinner: NgxSpinnerService,
    ) {}

    ngOnInit(): void {
        this.loaderToggleSubscription = this.componentService.on(ToggleLoading, this.toggle);
    }

    toggle = (spinnerMessage: ISpinnerMessage) => {
        if (!this.platform.isBrowser) {
            return;
        }

        this.message = spinnerMessage.message;

        if (spinnerMessage.start) {
            setTimeout(this.show, SpinnerComponent.showTimeout);
        } else {
            setTimeout(this.hide, SpinnerComponent.hideTimeout);
        }
    };

    setHideTask() {
        this.hideCheckIntervalId = setInterval(() => {
            this.toggle({ start: false, message: null });
        }, SpinnerComponent.hideTaskInterval);
    }

    private show = () => {
        if (this.webApiData.pendingRequestsCount === 0 || this.showSpinner || this.addToDom) {
            return;
        }

        this.setHideTask();

        this.addToDom = true;
        this.hideScroll();
        this.spinner.show();
        this.cd.markForCheck();
        this.showTimeoutId = setTimeout(() => {
            this.showSpinner = true;
            this.cd.markForCheck();
        }, 50);
    };

    private hide = () => {
        clearTimeout(this.showTimeoutId);

        if (this.webApiData.pendingRequestsCount !== 0 || !this.addToDom) {
            return;
        }

        clearInterval(this.hideCheckIntervalId!);
        this.spinner.hide();
        this.showSpinner = false;
        this.cd.markForCheck();
        setTimeout(() => {
            this.addToDom = false;
            this.showScroll();
            this.cd.markForCheck();
        }, SpinnerComponent.waitTillHideTimeout);
    };

    private hideScroll() {
        if (this.platform.isBrowser) {
            window.document.body.classList.add('spinner-opened');
        }
    }

    private showScroll() {
        if (this.platform.isBrowser) {
            window.document.body.classList.remove('spinner-opened');
        }
    }
}
