import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobeState } from './globe-state';
import { GlobeStateService } from './globe-state.service';

@Injectable({ providedIn: 'root' })
export class UnityGlobeService {
    readonly overlayImage$: Observable<string | null>;
    hideGlobe$ = new BehaviorSubject<boolean>(localStorage.getItem('hide-globe') === 'true');

    private _unityInstance: any;
    private canvasInstance: HTMLCanvasElement;
    private overlayImageSubject = new BehaviorSubject<string | null>(null);
    private highlights: number[] = [];

    get instance(): any {
        if (this.hideGlobe$.value) {
            return {
                SendMessage: () => {},
            };
        }
        if (!this._unityInstance || !this.canvasInstance) {
            throw new Error('Instance not yet initialized!');
        }
        return this._unityInstance;
    }

    constructor(private notifier: NotifierService, private globeStateService: GlobeStateService) {
        // private adminService: AdminService,
        const win = window as any;
        win.globe = this;
        this.overlayImage$ = this.overlayImageSubject.asObservable();
    }

    getCenter(): { x: number; y: number } {
        if (!this.canvasInstance) {
            return {
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            };
        }
        return {
            x: this.canvasInstance.offsetWidth / 2,
            y: this.canvasInstance.offsetHeight / 2,
        };
    }

    register(unityInstance: any, canvasInstance: HTMLCanvasElement) {
        this._unityInstance = unityInstance;
        this.canvasInstance = canvasInstance;
    }

    disableInput(): void {
        if (!this._unityInstance) {
            return;
        }
        this.instance.SendMessage('JSBridge', 'SetInputEnabled', 0);
    }

    enableInput(): void {
        if (!this._unityInstance) {
            return;
        }
        this.instance.SendMessage('JSBridge', 'SetInputEnabled', 1);
    }

    goToCell(cellID: number, zoomLevel: number = 10, travelSpeed: number = 1250): void {
        const value = JSON.stringify({ cellID: cellID, zoomLevel, travelSpeed });
        this.instance.SendMessage('JSBridge', 'GoToCell', value);
    }

    setCellsData(data: string, ownedTokens: number[]): void {
        this.instance.SendMessage('JSBridge', 'InitializeCellData', data);
        for (const ownedToken of ownedTokens) {
            this.setCellData(ownedToken, 4);
        }
        this.highlightCells(this.highlights);
    }

    setCellData(cellID: number, status: number): void {
        this.instance.SendMessage('JSBridge', 'UpdateCell', JSON.stringify({ cellID: cellID, state: status }));
        this.highlightCells(this.highlights);
    }

    filterCells(status: number): void {
        this.instance.SendMessage('JSBridge', 'FilterCells', status);
    }

    updateCellSprites(attributes: string): void {
        this.instance.SendMessage('JSBridge', 'UpdateCellSprites', attributes);
    }

    upDateCell(cellID: number, state: number): void {
        this.instance.SendMessage('JSBridge', 'SetLineColor', JSON.stringify({ cellID, state }));
    }

    setLineColor(r: number, g: number, b: number, a: number): void {
        if (a > 0 && a < 1) {
            throw new Error('Setting alpha between 0 and 1, must be integer.');
        }
        this.instance.SendMessage('JSBridge', 'SetLineColor', JSON.stringify({ r, g, b, a }));
    }

    highlightCells(cellIDs: number[], r: number = 51, g: number = 255, b: number = 252, a: number = 100): void {
        this.clearAllCellHighlights();
        for (const cellID of cellIDs) {
            this.instance.SendMessage('JSBridge', 'HighlightCell', JSON.stringify({ cellID, r, g, b, a }));
            this.highlights.push(cellID);
        }
    }

    highlightCell(cellID: number, r: number = 51, g: number = 255, b: number = 252, a: number = 100): void {
        this.clearCellHighlight(cellID);
        this.instance.SendMessage('JSBridge', 'HighlightCell', JSON.stringify({ cellID, r, g, b, a }));
        this.highlights.push(cellID);
    }

    clearCellHighlight(cellID: any): void {
        this.highlights = this.highlights.filter((cId) => cId === cellID);
        this.instance.SendMessage('JSBridge', 'ClearCellHighlight', cellID);
    }

    clearAllCellHighlights(): void {
        this.instance.SendMessage('JSBridge', 'ClearAllCellHighlights');
        this.highlights = [];
    }

    addLine(cellID: number, screenX: number, screenY: number): void {
        screenY = this.canvasInstance ? this.canvasInstance.offsetHeight - screenY : window.innerHeight - screenY;
        screenY = Math.floor(screenY * window.devicePixelRatio);
        screenX = Math.floor(screenX * window.devicePixelRatio);
        this.instance.SendMessage('JSBridge', 'AddLine', JSON.stringify({ cellID, screenX, screenY }));
    }

    removeLine(cellId: number): void {
        this.instance.SendMessage('JSBridge', 'RemoveLine', cellId);
    }

    clearLines(): void {
        this.instance.SendMessage('JSBridge', 'ClearLines');
    }

    cellScreenPosition(cellId: number): void {
        this.instance.SendMessage('JSBridge', 'CellScreenPosition', cellId);
    }

    goToTileView(cellId: number): void {
        this.globeStateService.setState(GlobeState.Loading);
        this.instance.SendMessage('JSBridge', 'GoToTileView', cellId);
    }
}
