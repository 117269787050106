import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { interval, Observable, of } from 'rxjs';
import { LocalOrder } from './ertha/models/order';
import { Rates } from './ertha/models/rates';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { CaptchaService } from './captcha.service';
import { WebApiService } from '../web-api/web-api.service';
import {
    AddManyTokensRequest,
    Classifier,
    CreateOrderRequest,
    CreateTokenRequest,
    GetPriceWithDiscountRequest,
    GetTokensRequest,
    GetTokensResponse,
    GetUserDetailsRequest,
    RegisterRequest,
    TokenResponseModel,
    User,
    WithIdRequest,
    WithIdsRequest,
} from '../web-api/api-contract';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    readonly rates$: Observable<Rates>;

    constructor(private webApi: WebApiService, private captchaService: CaptchaService) {
        this.rates$ = interval(300000).pipe(
            startWith(0),
            switchMap(() => this.getRates()),
            shareReplay(1),
        );
    }

    createOrder(tokenIds: string[], discountWalletOrAlias?: string): Observable<LocalOrder> {
        return this.captchaService.getToken('orders').pipe(
            switchMap((token) => {
                const headers = new HttpHeaders({
                    CaptchaToken: token,
                });
                return this.webApi.post('orders/create', { tokenIds, discountWalletOrAlias } as CreateOrderRequest, {
                    options: { headers },
                });
            }),
        );
    }

    cancelOrder(orderId: string): Observable<void> {
        return this.webApi.post('orders/cancel', { id: orderId });
    }

    getRates(): Observable<Rates> {
        return this.webApi.post('settings/get-rates', {});
    }

    getTokens({
        page,
        type,
        filters,
    }: {
        page: number;
        type: string[];
        filters?: string[];
    }): Observable<GetTokensResponse> {
        return this.webApi.post('tokens/get-all', {
            currentPage: page,
            type,
            filters,
        } as GetTokensRequest);
    }

    getClassifiers(): Observable<Classifier[]> {
        return this.webApi.post('classifiers/get-all', {});
    }

    tryCreateUser(user: Partial<User>): Observable<User> {
        return this.webApi.post('auth/try-created-user', user);
    }

    getUserDetails(request: GetUserDetailsRequest): Observable<User> {
        return this.webApi.post('auth/get-user-details', request);
    }

    register(user: RegisterRequest): Observable<User> {
        return this.webApi.post('auth/register', user);
    }

    getTokensByIds(ids: string[]): Observable<TokenResponseModel[]> {
        return this.webApi.post('tokens/get-tokens-by-ids', { ids } as WithIdsRequest);
    }

    getToken(id: string): Observable<TokenResponseModel> {
        return this.webApi.post('tokens/get-by-nft-id', { id } as WithIdRequest);
    }

    getAccountTokens(accountId: string): Observable<TokenResponseModel[]> {
        return this.webApi.post('tokens/get-account-tokens', { id: accountId } as WithIdRequest);
    }

    updateToken(token: CreateTokenRequest): Observable<any> {
        return this.webApi.post('tokens/update', token);
    }

    createToken(token: CreateTokenRequest): Observable<any> {
        return this.webApi.post('tokens/create', token);
    }

    createMultiToken(request: AddManyTokensRequest): Observable<string[]> {
        return this.webApi.post('tokens/create-many', request);
    }

    getBountyDiscountPrice(price: number, discountWalletOrAlias: string): Observable<number> {
        return this.webApi.post('orders/get-price-with-discount', {
            price,
            discountWalletOrAlias,
        } as GetPriceWithDiscountRequest);
    }

    getStatus(): Observable<string> {
        return this.webApi
            .getBuffer({
                url: 'tokens/status.bin',
                returnRawResponse: true,
            })
            .pipe(map((arrayBuffer) => this.arrayBufferToBase64(arrayBuffer)));
    }

    private arrayBufferToBase64(buffer: ArrayBuffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}
