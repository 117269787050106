import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertToBnb'
})
export class ConvertToBnbPipe implements PipeTransform {

    transform(bnbPrice: number | string): number {
        return Math.round(parseInt(bnbPrice as string) / 10000000000000) / 100000;
    }

}
