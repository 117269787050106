import { Injectable } from '@angular/core';
import { Store } from '@ng-state/store';
import { NotifierService } from 'angular-notifier';
import { EMPTY, Observable, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { StatusService } from './ertha/status.service';
import { CartState, MarketplaceState, RootState } from '../../state';
import { CartItem } from '../../cart/models/cart-item';
import { LocalOrder } from './ertha/models/order';
import { SocketPayload } from '../models';
import { TokenStatus } from '../enums/token-status';
import { EthereumApi } from './ethereum/ethereum.api';
import { AnalyticsService } from './analytics/analytics.service';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    private store$: Store<CartState>;
    private destroy$ = new Subject<any>();

    constructor(
        private erthaApi: TokenService,
        private ethereumApi: EthereumApi,
        private notifier: NotifierService,
        private statusService: StatusService,
        private analyticsService: AnalyticsService,
        rootStore: Store<RootState>,
    ) {
        this.store$ = rootStore.select(['cart']);
    }

    init() {
        this.initializeStatusMonitoring();
        this.initializeOrders();
    }

    destroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    private initializeOrders() {
        this.store$.select(['serializable']).storage.load({}, true);
    }

    private initializeStatusMonitoring(): void {
        this.statusService.tokenStatusChange$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
            this.store$.update((state) => {
                this.handleCompletedOrders(state, data);
                this.handleTokenReservation(state, data);
            });

            this.store$.select(['serializable']).storage.save();
        });
    }

    private handleTokenReservation(state: CartState, data: SocketPayload) {
        if (data.tokenStatus === TokenStatus.isReserved) {
            data.tokens.forEach((token) => {
                const tokenIndex = state.tokens.findIndex((t) => t._id === token._id);
                if (tokenIndex !== -1) {
                    state.tokens[tokenIndex].isReserved = true;
                }
            });
        }
    }

    private handleCompletedOrders(state: CartState, data: SocketPayload) {
        if (data.tokenStatus !== TokenStatus.hasOwner) {
            return;
        }

        let accountId;
        this.ethereumApi.status$.pipe(take(1)).subscribe((status) => {
            accountId = status.accountId;
        });

        if (!data.metadata || data.metadata!['senderAddress'] !== accountId) {
            return;
        }

        data.tokens.forEach((token) => {
            if (state.serializable.orders.length === 0) {
                return;
            }

            const orderIndex = state.serializable.orders.findIndex(
                (t) => t.orderItems.filter((oi) => oi.token._id === token._id).length > 0,
            );

            if (orderIndex !== -1) {
                const order = state.serializable.orders[orderIndex];
                state.serializable.orders.splice(orderIndex, 1);
                this.analyticsService.onPurchase(order);
                this.notifier.notify('success', 'Congratulations. Order has been completed.');
            }
        });
    }

    addToken(token: CartItem): void {
        this.store$.update((tokens) => {
            if (!tokens.tokens.find((i) => i._id === token._id)) {
                tokens.tokens.push(token);
                this.analyticsService.onAddToCart(token);
            } else {
                this.notifier.notify('warning', `Item #${token._id} is already in the cart.`);
            }
        });
    }

    isTokenInCart(tokenId: string): Observable<boolean> {
        return this.store$.map((state) => {
            return state.tokens.findIndex((t) => t._id === tokenId) !== -1;
        });
    }

    clearCart(): void {
        this.store$.update((state) => {
            state.tokens = [];
        });
    }

    createOrder(discountWalletOrAlias?: string): Observable<LocalOrder> {
        return this.store$.select<LocalOrder[]>(['serializable', 'orders']).pipe(
            take(1),
            switchMap((orders) => {
                if (orders.filter((o) => !o.isCompleted).length > 2) {
                    throw new Error(`You can't create more than 3 orders.`);
                }
                return this.store$.pipe(
                    take(1),
                    switchMap((state) => {
                        if (state.tokens.length === 0) {
                            this.notifier.notify('info', 'You have no land plots in cart that can be purchased.');
                            return EMPTY;
                        }
                        const tokenIds = state.tokens.map((t) => t._id);

                        return this.erthaApi.createOrder(tokenIds, discountWalletOrAlias).pipe(
                            tap((order) => {
                                this.addOrder(order);
                                this.clearCart();
                            }),
                        );
                    }),
                );
            }),
        );
    }

    removeToken(tokenId: string): void {
        this.store$.update((state) => {
            const index = state.tokens.findIndex((t) => t._id === tokenId);
            if (index !== -1) {
                const cartItem = state.tokens[index];
                state.tokens.splice(index, 1);
                this.analyticsService.onRemoveFromCart(cartItem);
            }
        });
    }

    private addOrder(order: LocalOrder): void {
        this.store$.update((tokens) => {
            tokens.serializable.orders.push(order);
        });
        this.store$.select(['serializable']).storage.save();
    }
}
