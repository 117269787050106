import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'fe-central-message',
    styleUrls: ['./central-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <mat-icon class="info-icon" *ngIf="!loading; else loadingRef">{{ icon }}</mat-icon>
        <ng-template #loadingRef>
            <div class="spinner"></div>
        </ng-template>
        <div class="text" [title]="label">
            <div class="title">{{ label }}</div>
            <div class="link"><ng-content></ng-content></div>
        </div>
    `,
})
export class CentralMessageComponent {
    @Input() icon: string;
    @Input() label: string;
    @Input() loading: boolean;
}
