import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertToDollar',
})
export class ConvertToDollarPipe implements PipeTransform {
    transform(price: number | string): string {
        const sum = parseInt(price as string) / 1000000000000000000;
        return sum.toFixed(4);
    }
}
