import { AdminPanelState } from './admin-panel/models/state.model';
import { CartItem } from './cart/models/cart-item';
import { ClientMarketplaceState } from './marketplace/models/state.model';
import { GlobeTileInfoData, LoggedInUser } from './infrastructure/models';
import { LocalOrder } from './infrastructure/services/ertha/models/order';
import { Classifier, TokenAttribute } from './infrastructure/web-api/api-contract';

export const state = {
    root: {
        user: {},
        marketplaceType: 'ertha',
        classifiers: [] as Classifier[],
    },
    cart: {
        bountyDiscountAccount: '',
        priceWithBountyDiscount: 0,
        tokens: [] as CartItem[],
        serializable: {
            orders: [
                /*  {
                created_at: '2022-02-16T13:25:52.206Z',
                expires_at: '2022-02-18T13:25:52.206Z',
                id: 'f0793848-22e7-42a0-91f0-8e2a6210921a',
                payment_address: '0x62cC3944f40547c8926D88Eeb788281f0CEF88bE',
                payment_amount_bnb: '2300000000000000',
                payment_amount_ertha: '-1',
                payment_amount_usdc: '1000000000000000000',
                payment_amount_usdt: '1000000000000000000',
                status: OrderStatusEnum.Reservation,
                tokens: [200180],
                is_completed: false,
            }, */
            ] as unknown as LocalOrder[],
            showOrderWarning: true,
        },
    },
    globe: {
        selectedTiles: [] as GlobeTileInfoData[],
    },
    adminPanel: {
        isOpened: false,
        token: {
            type: [] as string[],
            isForSale: false,
            NFTTokenId: 0,
            price: 0,
            discountInPercent: 0,
            image: '',
            name: '',
            attributes: [] as TokenAttribute[],
        },
        attributesInput: {
            name: '',
            measureUnits: '',
            type: '',
            value: '',
            metadata: {},
        },
        multiTokenUpload: {
            file: '',
            mintAccount: '',
            mintToken: false,
        },
    },
} as MarketplaceState;

export interface MarketplaceState {
    root: RootState;
    marketplace: ClientMarketplaceState;
    cart: CartState;
    globe: GlobeState;
    adminPanel: AdminPanelState;
}

export interface RootState {
    marketplaceType: string;
    user?: LoggedInUser;
    classifiers: Classifier[];
}

export interface CartState {
    bountyDiscountAccount: string;
    priceWithBountyDiscount: number;
    tokens: CartItem[];
    serializable: {
        orders: LocalOrder[];
        showOrderWarning: boolean;
    };
}

export interface GlobeState {
    selectedTiles: GlobeTileInfoData[];
}
