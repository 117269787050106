import { Injectable } from '@angular/core';
import { Store } from '@ng-state/store';
import { take } from 'rxjs';
import { TokenAttribute, TokenResponseModel } from '../web-api/api-contract';
import { RootState } from '../../state';

@Injectable({ providedIn: 'root' })
export class TokenDataService {
    constructor(private store: Store<RootState>) {}

    getPurchaseInfo(token: TokenResponseModel): { title: string; info: string } {
        let marketplaceType;
        this.store.pipe(take(1)).subscribe((state) => {
            marketplaceType = state.marketplaceType;
        });

        if (marketplaceType === 'ertha') {
            return {
                title: 'Country',
                info: this.getCountries(token)[0]?.value || '',
            };
        }

        return {
            title: '',
            info: '',
        };
    }

    getCountries(token: TokenResponseModel) {
        return this.getAttributes(token, 'country');
    }

    getCities(token: TokenResponseModel) {
        return this.getAttributes(token, 'city');
    }

    getResources(token: TokenResponseModel) {
        return this.getAttributes(token, 'resource');
    }

    private getAttributes(token: TokenResponseModel, type: string): TokenAttribute[] {
        return this.hasAttributes(token) ? token.attributes.filter((a) => a.type === type) : ([] as TokenAttribute[]);
    }

    private hasAttributes(token: TokenResponseModel) {
        return token && token.attributes && token.attributes.length > 0;
    }
}
