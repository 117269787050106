<header class="bar">
        <div class="top">
            <div class="left">
                <fe-ethereum-status></fe-ethereum-status>
                <!-- <span> Buy ${{theme}} - <span class="money">$0.05</span> <mat-icon svgIcon="down-arrow"></mat-icon> </span>
                <span> Stake ${{theme}} - <span class="money">1000$ APY</span>  <mat-icon svgIcon="down-arrow"></mat-icon></span>
                <span>Get free NFT <mat-icon svgIcon="down-arrow"></mat-icon></span> -->
            </div> 
            <img *ngIf="theme === 'spellfire'" class="logo" [src]="'../assets/img/' + theme + '/logo.png'"/>
            <div class="right socials">
                <a target="_blank" href="https://twitter.com/ErthaGame">
                    <mat-icon svgIcon="twitter"></mat-icon>
                </a>
                <a target="_blank" href="https://www.facebook.com/Ertha-1523173454647823">
                    <mat-icon svgIcon="facebook"></mat-icon>
                </a>
                <a target="_blank" href="https://www.youtube.com/channel/UCHiXL-GSDqd9jIa1hsr20Kw">
                    <mat-icon svgIcon="youtube"></mat-icon>
                </a>
                <a target="_blank" href="https://t.me/Erthium">
                    <mat-icon svgIcon="telegram"></mat-icon>
                </a>
                <a target="_blank" href="https://erthium.medium.com">
                    <mat-icon svgIcon="medium"></mat-icon>
                </a>
                <a target="_blank" href="https://discord.com/invite/ertha">
                    <mat-icon svgIcon="discord"></mat-icon>
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/erthium">
                    <mat-icon svgIcon="linkedin"></mat-icon>
                </a>
            </div> 
        </div>
        <div class="mid"> 
            <div class="left">
                <!-- <span>Play <mat-icon svgIcon="down-arrow"></mat-icon> </span> -->
                <span [routerLink]="['/marketplace']">Trade <mat-icon class="dropdown-arrow" svgIcon="down-arrow"></mat-icon> </span>
                <span [routerLink]="['/globe']">Globe</span>
                <!-- <span>Socialize</span> -->
                <!-- <span>Learn</span> -->
            </div>
            <span class="space"></span>
            <span class="space-left"></span>
            <mat-icon *ngIf="theme !== 'spellfire'" class="logo" svgIcon="logo"></mat-icon>
            <span class="space-right"></span>
            <span class="space"></span>
            <div class="right">
                <!-- <fe-search-box></fe-search-box> -->
                <mat-icon [routerLink]="['/cart']" svgIcon="cart"></mat-icon>
                <!-- <mat-icon svgIcon="bell"></mat-icon> -->
                <mat-icon [routerLink]="['/auth']" svgIcon="user"></mat-icon>
                <span *ngIf="authService.isLoggedIn(['admin']) | async else login" [routerLink]="['/my-tokens']">Tokens</span>
                <ng-template #login><span [routerLink]="['/auth']">Login</span></ng-template>
            </div> 
        </div>
        <!-- class="bot" [ngIf]="theme == 'moon'" scrolling feed bar here-->
</header>