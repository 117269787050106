import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentState, HasStateActions } from '@ng-state/store';
import { CartService } from '../../infrastructure/services/cart.service';
import { TokenService } from '../../infrastructure/services/token.service';
import { CartStateActions } from '../actions/cart.actions';

@ComponentState(CartStateActions)
@Component({
    selector: 'fe-cart-items',
    templateUrl: './cart-items.component.html',
    styleUrls: ['./cart-items.component.scss'],
})
export class CartComponent extends HasStateActions<CartStateActions> implements OnInit {
    form: FormGroup;

    constructor(
        cd: ChangeDetectorRef,
        private cartService: CartService,
        private router: Router,
        private fb: FormBuilder,
        private erthaApi: TokenService,
    ) {
        super(cd);
    }

    override ngOnInit(): void {
        this.initForm();
        this.bindFormToState();
    }

    trackByFn(index: number, item: any) {
        return item.id;
    }

    removeFromCart(tokenId: string) {
        this.cartService.removeToken(tokenId);
    }

    createOrder() {
        this.cartService.createOrder(this.actions.state?.bountyDiscountAccount).subscribe(() => {
            this.router.navigateByUrl('/orders');
        });
    }

    hasError(filedName: string): boolean {
        const field = this.form.get(['token', filedName]);
        return field!.dirty && field!.invalid;
    }

    getBountyDiscountPrice(currentPrice: number) {
        this.erthaApi.getBountyDiscountPrice(currentPrice, this.form.value.bountyDiscountAccount).subscribe((price) => {
            this.actions.setPriceWithDiscount(price);
        });
    }

    private initForm() {
        this.form = this.fb.group({
            bountyDiscountAccount: [this.actions.state?.bountyDiscountAccount],
        });
    }

    private bindFormToState() {
        this.actions.store.form.bind(this.form, { debounceTime: 500 });
    }
}
