import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'button[fe-button]',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('loading', [
            state('void', style({ opacity: 0 })),
            state('*', style({ opacity: 1 })),
            transition('* => void, void => *', animate('0ms')),
        ]),
        trigger('buttonText', [
            state('hidden', style({ opacity: 0 })),
            state('*', style({ opacity: 1 })),
            transition('* => hidden, hidden => *', animate('0ms')),
        ]),
    ],
    templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
    @Input() icon: string | undefined;
    @Input() loading: boolean = false;

    constructor() {}

    ngOnInit(): void {}
}
