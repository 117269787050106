import { Pipe, PipeTransform } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalOrder } from '../../infrastructure/services/ertha/models/order';

@Pipe({
    name: 'isOrderExpired',
})
export class IsOrderExpiredPipe implements PipeTransform {
    transform(order: LocalOrder): Observable<boolean> {
        return interval(1000).pipe(
            startWith(null),
            map(() => {
                const timeDiff = new Date().getTime() - new Date(order.expiresAt).getTime();
                return timeDiff > 0;
            }),
        );
    }
}
