import { HasStore, InjectStore } from '@ng-state/store';
import { Classifier } from '../infrastructure/web-api/api-contract';
import { RootState } from '../state';

@InjectStore('root')
export class AppStateActions extends HasStore<RootState> {
    set classifiers(classifiers: Classifier[]) {
        this.store.update((state) => {
            state.classifiers = classifiers;
        });
    }
}
