export interface TokenBase {
    _id: string;
    NFTTokenId: number;
    name?: string;
    price: number;
    discountInPercent?: number;
}

export enum TokenResourceKey {
    crops = 'crops',
    fruit = 'fruit',
    minerals = 'minerals',
    rawMeat = 'rawMeat',
    titanium = 'titanium',
    fish = 'fish',
    iron = 'iron',
    oil = 'oil',
    stone = 'stone',
    rock = 'rock',
    wood = 'wood',
    port = 'port',
    volcano = 'volcano',
    airport = 'airport',
    mystery = 'mystery',
    water = 'water',
    helium3 = 'helium3',
    thorium = 'thorium',
    regolith = 'regolith',
    crystal = 'crystal',
    portals = 'portals',
}
