import { Component, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { DialogService } from '@ngneat/dialog';
import { Observable } from 'rxjs';
import { AuthService } from '../infrastructure/services/auth.service';
import { EthereumStatus } from '../infrastructure/services/ethereum/ethereum-status';
import { EthereumApi } from '../infrastructure/services/ethereum/ethereum.api';

@Component({
    selector: 'fe-ethereum-status',
    styleUrls: ['./ethereum-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-container *ngIf="status$ | async as status">
            <ng-container *ngIf="!status.error; else errorRef">
                <ng-container *ngIf="!status.notAvailable; else notAvailableRef">
                    <ng-container *ngIf="!status.loading; else loadingRef">
                        <div class="account-info" *ngIf="status.accountId; else noAccountRef">
                            <fe-central-message class="success" icon="done" [label]="'MetaMask Connected'">
                            </fe-central-message>
                        </div>
                        <ng-template #noAccountRef>
                            <fe-central-message icon="info" [label]="'Not logged in to Metamask...'">
                                <mat-icon (click)="ethereumApi.login()">login</mat-icon>
                            </fe-central-message>
                        </ng-template>
                    </ng-container>
                    <ng-template #loadingRef>
                        <fe-central-message icon="info" [label]="'Waiting for user action...'" [loading]="true">
                        </fe-central-message>
                    </ng-template>
                </ng-container>
            </ng-container>
            <ng-template #errorRef>
                <fe-central-message class="error" icon="error" [label]="status.error?.message">
                    <div class="link" (click)="ethereumApi.login()">Try again</div>
                </fe-central-message>
            </ng-template>
        </ng-container>
        <ng-template #notAvailableRef>
            <fe-central-message class="error" icon="error" label="MetaMask not available">
                <a class="link" href="https://metamask.io/download.html" target="_blank">Download</a>
            </fe-central-message>
        </ng-template>
        <div
            class="login-as-admin"
            *ngIf="adminContext"
            [style.left.px]="adminContext?.left"
            [style.top.px]="adminContext?.top"
        >
            Switch to admin
        </div>
    `,
})
export class EthereumStatusComponent {
    status$: Observable<EthereumStatus>;

    adminContext: { left: number; top: number };

    constructor(public ethereumApi: EthereumApi, private dialog: DialogService, private authService: AuthService) {
        this.status$ = ethereumApi.status$;
    }
}
