import { Injectable } from '@angular/core';
import { Order } from '../../web-api/api-contract';
import { TokenBase } from '../ertha/models/token';
import { AddToCartEvent, CheckoutEvent, Product, PurchaseEvent, RemoveFromCartEvent } from './analytics.events';

declare global {
    interface Window {
        dataLayer: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    public onAddToCart(token: TokenBase) {
        let event = <AddToCartEvent>{
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'USD',
                add: {
                    products: [this.tokenToProduct(token)],
                },
            },
        };

        this.pushEvent(event);
    }

    public onRemoveFromCart(token: TokenBase) {
        let event = <RemoveFromCartEvent>{
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: [this.tokenToProduct(token)],
                },
            },
        };

        this.pushEvent(event);
    }

    public onCheckout(tokens: TokenBase[]) {
        let event = <CheckoutEvent>{
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 1,
                    },
                    products: this.tokensToProducts(tokens),
                },
            },
        };

        this.pushEvent(event);
    }

    public onPurchase(order: Order) {
        let revenue = this.convertToUSD(parseFloat(order.paymentAmountUSDT));
        let event = <PurchaseEvent>{
            event: 'purchase',
            ecommerce: {
                purchase: {
                    actionField: {
                        id: order._id.toString(),
                        affiliation: 'Ertha',
                        revenue: revenue,
                    },
                    products: this.tokensToProducts(
                        order.orderItems.map((oi) => ({
                            _id: oi.token._id.toString(),
                            NFTTokenId: oi.token.NFTTokenId,
                            name: oi.token.name || '',
                            price: oi.token.price,
                        })),
                    ),
                },
            },
        };

        this.pushEvent(event);
    }

    private pushEvent(event: any) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push(event);
    }

    private tokensToProducts(tokens: TokenBase[]): Product[] {
        let products: Product[] = [];
        tokens.forEach((token) => {
            products.push(this.tokenToProduct(token));
        });
        return products;
    }

    private tokenToProduct(token: TokenBase): Product {
        return <Product>{
            name: token.name,
            id: token._id.toString(),
            price: token.price,
            quantity: 1,
        };
    }

    private convertToUSD(usdt: number): string {
        let usdtToUsd = 1 / 1000000000000000000;
        return Number(usdt * usdtToUsd).toFixed(2);
    }
}
