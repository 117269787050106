import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RequestsInterceptor } from './infrastructure/web-api/requests.interceptor';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StoreModule } from '@ng-state/store';
import { ImmerDataStrategyModule } from '@ng-state/immer-data-strategy';
import { state } from './state';
import { environment } from '../environments/environment';
import { NotifierModule } from 'angular-notifier';
import { DialogModule } from '@ngneat/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartModule } from './cart/cart.module';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from './shared/shared.module';
import { EthereumStatusModule } from './ethereum-status/ethereum-status.module';
import { AdminPanelModule } from './admin-panel/admin-panel.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { HeaderComponent } from './header/header.component';
import { ErthaFooterComponent } from './footer/ertha-footer/ertha-footer.component';
import { SpellfireFooterComponent } from './footer/spellfire-footer/spellfire-footer.component';
import { MoonFooterComponent } from './footer/moon-footer/moon-footer.component';

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        HeaderComponent,
        ErthaFooterComponent,
        SpellfireFooterComponent,
        MoonFooterComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxSpinnerModule,
        StoreModule.provideStore(state, environment.production),
        ImmerDataStrategyModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        SharedModule,
        EthereumStatusModule,
        AdminPanelModule,
        NotifierModule.withConfig({
            position: {
                horizontal: { position: 'right' },
                vertical: { position: 'top' },
            },
            behaviour: {
                autoHide: 3000,
            },
        }),
        DialogModule.forRoot(),
        CartModule,
        MatIconModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: RequestsInterceptor, multi: true }],
    bootstrap: [AppComponent],
})
export class AppModule {}
