import { Injectable } from '@angular/core';
import { Observable, Subject, throttleTime } from 'rxjs';
import io, { Socket } from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import { SocketPayload } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class StatusService {
    readonly tokenStatusChange$: Observable<SocketPayload>;
    private tokenStatusChangeSubject = new Subject<SocketPayload>();
    private ws: Socket;

    constructor() {
        this.tokenStatusChange$ = this.tokenStatusChangeSubject.asObservable().pipe(throttleTime(10));
    }

    connect(): void {
        this.ws = io(environment.apiUrl);
        this.ws.on('GATEWAY_MESSAGE', (data: SocketPayload) => {
            this.tokenStatusChangeSubject.next(data);
        });
        this.ws.on('disconnect', () => {
            console.log('Disconnected');
        });
        this.ws.on('connect', () => {
            console.log('Connected...');
        });
        this.ws.connect();
    }

    disconnect(): void {
        this.ws.disconnect();
        this.ws.close();
    }
}
