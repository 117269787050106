import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComponentState, HasStateActions, NgFormStateManager, ShouldUpdateStateParams } from '@ng-state/store';
import { cartAnimations } from '../shared/animations/cart.animations';
import { AdminPanelStateActions } from './actions/admin-panel.actions';
import { RxwebValidators, startsWith } from '@rxweb/reactive-form-validators';
import { TokenModel } from './models/token.model';
import { NotifierService } from 'angular-notifier';
import {
    combineLatest,
    debounceTime,
    defer,
    filter,
    from,
    iif,
    map,
    merge,
    mergeAll,
    Observable,
    of,
    race,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs';
import { TokenService } from '../infrastructure/services/token.service';
import { FileService } from '../infrastructure/services/file.service';
import { MarketplaceType } from '../infrastructure/enums/marketplace-type';
import { Classifier, CreateTokenRequest, TokenResponseModel } from '../infrastructure/web-api/api-contract';
import { AppService } from '../app.service';
import { DialogService } from '@ngneat/dialog';
import { Router } from '@angular/router';
import { TileSelectionService } from '../infrastructure/services/globe/tile-selection.service';
import { AdminService } from './admin.service';
import { PriceCalculationStrategy } from './price-calculation-strategies/price-calculation.strategy';
import {
    PriceCalculationStrategyKey,
    PriceCalculationStrategyMap,
} from './price-calculation-strategies/price-calcualtion-strategy-map';
import { MultiTokenUploadState } from './models/state.model';

@ComponentState(AdminPanelStateActions)
@Component({
    selector: 'fe-admin-panel',
    templateUrl: './admin-panel.component.html',
    styleUrls: ['./admin-panel.component.scss'],
    animations: [
        ...cartAnimations,
        trigger('adminContent', [
            state('void', style({ opacity: 0, height: 0 })),
            state('*', style({ opacity: 1, height: '*' })),
            transition('void => *, * => void', animate('300ms ease-out')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminPanelComponent extends HasStateActions<AdminPanelStateActions> implements OnInit, OnDestroy {
    private destroy$ = new Subject<any>();

    fileToUpload: File | null = null;
    form: FormGroup;
    multiTokenForm: FormGroup;
    ngFormStateManager: NgFormStateManager;
    ngMultipleTokensFormStateManager: NgFormStateManager;

    attributeColumns: string[] = ['type', 'name', 'value', 'measureUnits', 'metadata', 'remove'];

    @Input()
    marketplaceType: MarketplaceType;

    isFormSubmitted = false;
    isMultiTokenFormSubmitted = false;

    private tokenPriceCalculationStrategy: PriceCalculationStrategy;

    constructor(
        cd: ChangeDetectorRef,
        private fb: FormBuilder,
        private notifier: NotifierService,
        private erthaApi: TokenService,
        private fileService: FileService,
        private appService: AppService,
        private dialog: DialogService,
        private router: Router,
        private tileSelectionService: TileSelectionService,
        private location: Location,
        private adminService: AdminService,
        private injector: Injector,
    ) {
        super(cd);
    }

    override ngOnInit(): void {
        this.initForm();
        this.initTokenSearch();
        this.bindFormToState();
        this.listenToSelectedTokenEditEvent();
        this.initAutoPrice();
    }

    override ngOnDestroy() {
        this.ngFormStateManager.destroy();
        this.ngMultipleTokensFormStateManager.destroy();
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    listenToSelectedTokenEditEvent() {
        this.appService.onTokenEdit.pipe(takeUntil(this.destroy$)).subscribe((NFTTokenId) => {
            this.actions.isContentVisible = true;
            this.form.patchValue({
                token: {
                    NFTTokenId,
                },
            });
            this.fileToUpload = null;
        });
    }

    onContentVisibilityChange(isVisible: boolean) {
        this.actions.isContentVisible = isVisible;
    }

    getMetaData(item: { [index: string]: any }) {
        if (!item) {
            return '';
        }

        const key = Object.keys(item);
        if (key.length === 0) {
            return '';
        }

        return key.map((k) => `${k}:${item[k]}`).join(' / ');
    }

    onImageChange(files: FileList) {
        this.fileToUpload = files.item(0);
        this.actions.setImage(this.fileToUpload!.name);
    }

    onFileChange(files: FileList) {
        this.fileToUpload = files.item(0);
        this.actions.setFile(this.fileToUpload!.name);
    }

    unsetImage() {
        this.fileToUpload = null;
        this.actions.removeImage();
    }

    unsetFile() {
        this.fileToUpload = null;
        this.actions.removeFile();
    }

    hasError(filedName: string): boolean {
        const field = this.form.get(['token', filedName]);
        return (field!.dirty || this.isFormSubmitted) && field!.invalid;
    }

    hasMultiTokenFormError(filedName: string): boolean {
        const field = this.multiTokenForm.get([filedName]);
        return (field!.dirty || this.isMultiTokenFormSubmitted) && field!.invalid;
    }

    get selectedAttributeType() {
        const field = this.form.get(['attributesInput', 'type']);
        return field?.value || '';
    }

    getErrorMessage(filedName: string) {
        const errors = this.form.get(['token', filedName])?.errors;
        if (!errors) {
            return '';
        }

        return errors['required']
            ? 'This field is required'
            : errors['minlength']
            ? 'This field requires at least 1 attribute'
            : errors['digit']
            ? 'This field requires a number'
            : '';
    }

    onNewTokenButtonClick() {
        this.actions.store.reset();
        this.actions.isContentVisible = true;
        this.tileSelectionService.closeAll();
    }

    onMultiSubmit() {
        this.isMultiTokenFormSubmitted = true;

        if (this.multiTokenForm.invalid) {
            return;
        }

        const formData = new FormData();
        formData.append('tokens', this.fileToUpload!);

        if (this.actions.state?.multiTokenUpload.mintToken) {
            formData.append('mintAccount', this.actions.state?.multiTokenUpload.mintAccount);
        }

        this.erthaApi.createMultiToken(formData as any).subscribe((ids: string[]) => {
            if (ids.length > 0) {
                this.notifier.notify('success', `${ids.length} tokens created`);
            }

            this.unsetFile();
            this.isMultiTokenFormSubmitted = false;
        });
    }

    onSubmit() {
        this.isFormSubmitted = true;

        if (this.form.invalid) {
            return;
        }

        const hasNewTypeOrAttributes = this.hasNewTypeOrAttributes();

        iif(
            () => hasNewTypeOrAttributes,
            defer(() =>
                this.dialog
                    .confirm({
                        title: 'New token type or metadata',
                        body: 'You are about to add new token type or metadata. Are you sure?',
                    })
                    .afterClosed$.pipe(filter((result) => result)),
            ),
            defer(() => of('')),
        )
            .pipe(
                switchMap(() =>
                    iif(
                        () => !!this.fileToUpload,
                        defer(() => this.fileService.uploadImages(this.marketplaceType, [this.fileToUpload!])),
                        defer(() => from([[this.form.get(['token', 'image'])?.value]])),
                    ),
                ),
                map((uploadedImages) => uploadedImages[0]),
                tap((image) => {
                    this.form.patchValue({ token: { image } });
                }),
                switchMap((image: string) => {
                    const request = this.getCreateTokenRequest(image);

                    if (this.actions.state?.token._id) {
                        return this.erthaApi.updateToken(request);
                    } else {
                        return this.erthaApi.createToken(request);
                    }
                }),
                take(1),
            )
            .subscribe((tokenId: string) => {
                if (this.fileToUpload) {
                    this.fileToUpload = null;
                }

                this.notifier.notify(
                    'success',
                    `Token ${this.actions.state?.token?._id ? 'updated' : 'added'} successfully`,
                );

                if (!this.actions.state?.token._id) {
                    this.actions.addTokenId(tokenId);
                }

                if (hasNewTypeOrAttributes) {
                    this.appService.refreshClassifiers.next(true);
                }

                this.adminService.onTokenUpdated$.next(this.actions.state?.token!);
                this.unsetImage();
                this.isFormSubmitted = false;
            });
    }

    private hasNewTypeOrAttributes() {
        const newTokenTypes = this.actions.state!.token.type.filter((t) => t.endsWith('<new>'));
        const newAttributes =
            (this.actions.state!.token.attributes &&
                this.actions
                    .state!.token.attributes.flat()
                    .filter(
                        (a) => Object.values(a.metadata || {}).filter((m) => m.toString().endsWith('<new>')).length > 0,
                    )) ||
            [];

        return newTokenTypes.length > 0 || newAttributes.length > 0;
    }

    getClassifierByType(type: string): Observable<Classifier[]> {
        return this.appService.getClassifierByType(type);
    }

    getMetadataSuggestions(type: string, childOfType: string): Observable<string[]> {
        return this.appService.getClassifierByType(type, childOfType).pipe(
            map((classifiers: Classifier[]) => {
                return classifiers.map((c) => c.name);
            }),
        );
    }

    getTokenTypeSuggestions(type: string): Observable<string[]> {
        return this.getClassifierByType(type).pipe(
            map((classifiers: Classifier[]) => {
                return classifiers.map((c) => c.name);
            }),
        );
    }

    trackByFnClassifier(_: number, item: Classifier) {
        return item.name;
    }

    attributeMetadataInputValidator(value: string) {
        return value.indexOf(':') > 0;
    }

    attributeSuggestionExtractorFn(value: string) {
        return value.split(':')[0];
    }

    onAddAttributeClick() {
        this.actions.addAttribute();
    }

    private attributeValueFromStringArray(value: string[]) {
        if (!Array.isArray(value)) {
            return value;
        }

        const output: { [index: string]: any } = {};
        value.forEach((v) => {
            const [key, val] = v.split(':');
            output[key.trim()] = val.trim();
        });

        return output;
    }

    private initAutoPrice() {
        this.form
            .get(['token', 'autoPrice'])!
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
                if (value) {
                    this.actions.tokenPrice = this.tokenPriceCalculationStrategy.calculate(this.actions.state?.token!);
                }
            });
    }

    private initTokenSearch() {
        merge(
            this.form.get(['token', 'NFTTokenId'])!.valueChanges.pipe(debounceTime(500)),
            this.adminService.onTokenUpdated$.pipe(
                map((token) => token.NFTTokenId),
                startWith(null),
            ),
        )
            .pipe(
                filter((tokenId) => !!tokenId),
                switchMap((NFTTokenId: string) => {
                    return this.erthaApi.getToken(NFTTokenId);
                }),
                filter((token: TokenResponseModel) => !!token._id),
            )
            .subscribe((token: TokenResponseModel) => {
                token.attributes.forEach((attribute) => {
                    attribute.value =
                        typeof attribute.value === 'number' ? parseFloat(attribute.value).toFixed(3) : attribute.value;
                });

                this.actions.token = {
                    _id: token._id,
                    NFTTokenId: token.NFTTokenId,
                    attributes: token.attributes,
                    isForSale: token.isForSale,
                    price: token.price,
                    discountInPercent: token.discountInPercent,
                    type: token.type,
                    image: token.image,
                    name: token.name,
                };

                this.tokenPriceCalculationStrategy = this.injector.get<PriceCalculationStrategy>(
                    PriceCalculationStrategyMap.get(PriceCalculationStrategyKey(token.marketplaceType, token.type)),
                );

                if (this.router.url.toString().indexOf('-globe') > -1) {
                    this.location.replaceState(`${this.router.url.split('#')[0]}#${token.NFTTokenId}`);
                    this.tileSelectionService.selectTileCentered(token.NFTTokenId);
                }
            });
    }

    private bindFormToState() {
        this.ngFormStateManager = this.actions.store.form.bind(this.form, { debounceTime: 500 });
        this.ngMultipleTokensFormStateManager = this.actions.store
            .select(['multiTokenUpload'])
            .form.bind(this.multiTokenForm, { debounceTime: 300 })
            .onChange((state: MultiTokenUploadState) => {
                if (state.mintToken) {
                    this.multiTokenForm.controls['mintAccount'].enable();
                } else {
                    this.multiTokenForm.controls['mintAccount'].disable();
                }
            });
    }

    private initForm() {
        this.form = this.fb.group({
            token: this.fb.group({
                autoPrice: [false],
                image: [this.actions.state?.token.image, RxwebValidators.required()],
                NFTTokenId: [this.actions.state?.token.NFTTokenId, RxwebValidators.required()],
                type: [this.actions.state?.token.type, RxwebValidators.required()],
                name: [this.actions.state?.token.name],
                price: [this.actions.state?.token.price, [RxwebValidators.required(), RxwebValidators.digit()]],
                discountInPercent: [
                    this.actions.state?.token.discountInPercent,
                    [RxwebValidators.required(), RxwebValidators.digit()],
                ],
                isForSale: [this.actions.state?.token.isForSale, RxwebValidators.required()],
                attributes: [this.actions.state?.token.attributes],
            }),
            attributesInput: this.fb.group({
                type: [this.actions.state?.attributesInput.type],
                name: [this.actions.state?.attributesInput.name],
                value: [this.actions.state?.attributesInput.value],
                measureUnits: [this.actions.state?.attributesInput.measureUnits],
                metadata: [this.actions.state?.attributesInput.metadata],
            }),
        });

        this.multiTokenForm = this.fb.group({
            file: [this.actions.state?.multiTokenUpload.file, RxwebValidators.required()],
            mintToken: [this.actions.state?.multiTokenUpload.mintToken],
            mintAccount: [{ value: this.actions.state?.multiTokenUpload.mintAccount, disabled: true }],
        });
    }

    private getCreateTokenRequest(image: string) {
        if (this.actions.state?.token.attributes && this.actions.state?.token.attributes.length > 0) {
            this.actions
                .state!.token.attributes.filter((attribute) => !!attribute.metadata)
                .forEach((attribute) => {
                    attribute.metadata = this.attributeValueFromStringArray(attribute.metadata as never as string[]);
                });
        }

        return {
            ...(this.actions.state?.token._id ? { _id: this.actions.state?.token._id } : {}),
            type: this.actions.state?.token.type,
            name: this.actions.state?.token.name,
            image,
            isForSale: this.actions.state?.token.isForSale,
            price: parseInt(this.actions.state!.token.price.toString()),
            discountInPercent: parseInt(this.actions.state!.token.discountInPercent.toString()),
            attributes: this.actions.state?.token.attributes,
            NFTTokenId: parseInt(this.actions.state!.token.NFTTokenId.toString()),
            filters:
                this.actions.state?.token.attributes && this.actions.state.token.attributes.length > 0
                    ? this.actions.state?.token.attributes.map((attribute) => attribute.name.toLowerCase())
                    : [],
        } as CreateTokenRequest;
    }
}
