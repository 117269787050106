//from 77 repo
import { Component, ChangeDetectionStrategy } from '@angular/core';

//todo - remove inline styles
//todo - keep either this or spinner.component but not both

@Component({
    selector: 'fe-spinning-icon',
    styleUrls: ['./spinning-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <svg
            class="loader"
            xmlns="http://www.w3.org/2000/svg"
            style="margin: auto; overflow: hidden; background: none; display: block; shape-rendering: auto;"
            width="20px"
            height="20px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke-width="10"
                r="35"
                stroke-dasharray="164.93361431346415 56.97787143782138"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                ></animateTransform>
            </circle>
        </svg>
    `,
})
export class SpinningIconComponent {}
