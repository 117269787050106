<div class="mat-paginator-outer-container">
    <div class="mat-paginator-container">
        <div class="mat-paginator-range-actions">
            <button
                mat-icon-button
                type="button"
                class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-previous mat-icon-button mat-button-base"
                (click)="previousPage()"
                [attr.aria-label]="_intl.previousPageLabel"
                [matTooltip]="_intl.previousPageLabel"
                [matTooltipDisabled]="_previousButtonsDisabled()"
                [matTooltipPosition]="'above'"
                [disabled]="_previousButtonsDisabled()"
                [class.mat-button-disabled]="_previousButtonsDisabled()"
            >
                <svg class="mat-paginator-icon" viewBox="0 0 24 24" focusable="false">
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                </svg>
            </button>

            <div class="mat-paginator-range-label">
                {{ pageIndex + 1 }}
            </div>

            <button
                mat-icon-button
                type="button"
                class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-previous mat-icon-button mat-button-base"
                (click)="nextPage()"
                [attr.aria-label]="_intl.nextPageLabel"
                [matTooltip]="_intl.nextPageLabel"
                [matTooltipDisabled]="_nextButtonsDisabled()"
                [matTooltipPosition]="'above'"
                [disabled]="_nextButtonsDisabled()"
                [class.mat-button-disabled]="_nextButtonsDisabled()"
            >
                <svg class="mat-paginator-icon" viewBox="0 0 24 24" focusable="false">
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                </svg>
            </button>
        </div>
    </div>
</div>
