import { ActivatedRouteSnapshot, NavigationEnd, NavigationStart } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export class ComponentHelper {
    public static addBaseUrl(url: string): string {
        return `${window.location.origin}${url}`;
    }

    public static toObservable(item: any): Observable<any> {
        if (item == null) {
            return item;
        }

        return item instanceof Observable ? item : of(item).pipe(take(1));
    }

    public static navigationEndFilter(event: Observable<any>) {
        return event.pipe(
            filter((e) => e instanceof NavigationEnd),
            filter((e) => !(<NavigationEnd>e).url.startsWith('/api'))
        );
    }

    public static navigationStartFilter(event: Observable<any>) {
        return event.pipe(
            filter((e) => e instanceof NavigationStart),
            filter((e) => !(<NavigationEnd>e).url.startsWith('/api'))
        );
    }
}
