import { Subject } from 'rxjs';

export class BaseService {
    isDestroyed = new Subject();

    destroy() {
        this.isDestroyed.next(true);
        this.isDestroyed.complete();
    }
}
